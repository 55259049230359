<template>
  <div>
    <template v-for="(item, key) in selectDept">
      <div :key="key" v-if="item.useYn =='Y'">
        <h3 v-if="item.memberlist != null && item.memberlist.length > 0 && depth < 3" class="tit">{{item.deptNm}}</h3>
        <h4 v-if="item.memberlist != null && item.memberlist.length > 0 && depth >= 3" class="tit h4_t">{{item.deptNm}}</h4>
        <table v-if="item.memberlist != null && item.memberlist.length > 0" class="table1 mt20" :summary="`${item.deptNm}`+`${$t('orgGuide')}`">
          <caption>{{item.deptNm}} {{$t('info')}}</caption>
          <colgroup>
            <col width="*" />
            <col width="30%" />
            <col width="30%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">{{$t('responsibilities')}}</th>
              <th scope="col">{{$t('nm')}}</th>
              <th scope="col">{{$t('extensionTel')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item2, index2) in item.memberlist" :key="index2">
              <th scope="row" v-html="$sanitize(item2.workintro2)"></th>
              <td>{{item2.nm}}</td>
              <td>{{item2.tel}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <deptTable :key="`${key}sub`" :deptList="item.buseolist" :depth="item.depth+1"/>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    deptList: {
      type: Array,
      default: () => []
    },
    deptNo: {
      type: String
    },
    depth: {
      type: Number,
      default: 1
    }
  },
  computed: {
    selectDept(){
      if(this.deptNo == null || this.deptNo.length < 1){
        return this.deptList
      }else{
        return this.getDept(this.deptList, this.deptNo)
      }
    }
  },
  methods: {
    getDept(deptList, deptNo){
      for(const i in deptList){
        const dept = deptList[i]
        if(dept.deptNo == deptNo){
          return [dept]
        }else{
          const result = this.getDept(dept.buseolist, deptNo)
          if(result != null){
            return result
          }
        }
      }
    }
  }
}
</script>
