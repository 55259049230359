<template>
  <div id="board" style="width:100%;">
    <!-- 진행/종료 탭 고정 -->
    <div class="con_tab">
      <ul class="no2">
        <li :class="searchParam.vtime=='1'?'on':''">
          <a @click="changeTimeTab('1')" @keydown="doKeydownTimeTab($event, '1')" :tabindex="tabindex+1">{{$t('ing')}}</a>
        </li>
        <li :class="searchParam.vtime=='3'?'on':''">
          <a @click="changeTimeTab('3')" @keydown="doKeydownTimeTab($event, '3')" :tabindex="tabindex+2">{{$t('terminate')}}</a>
        </li>
      </ul>
    </div>
    <!-- 그룹 탭 -->
    <childTab v-if="isTab()" :grpList="grpList" v-model="currentTab" @changeTab="changeTab" :depth="depth" :tabindex="tabindex+3"/>
    <main-content v-if="isContent" :subYn="true" :contents="[{contentNo: subContentNo, contentType: subContentType}]" :grpList="grpList" :depth="depth+1" :tabindex="tabindex+100"/>
    <div v-if="!isContent && (dataItemList.length > 0 || headerList.length > 0)">
      <div class="event_skin">
        <!-- 검색 영역 -->
        <div class="search_w">
          <table style="width: 100%;">
            <tbody>
              <tr>
                <td align="right">
                  <div class="date_search">
                    <div>
                      <flat-pickr v-model="searchParam.postStDttm" :config="config" ref="dateComp" class="txt_input jsCalendar" :tabindex="tabindex+4" @keydown="doSearchKeywordEnter"/>
                      <a class="cal_btn" data-toggle>
                        <img src="@/assets/img/02preview/cal_btn.gif" :alt="$t('startSelect')">
                      </a>
                    </div>
                    <div class="pl5 pr5">~</div>
                    <div>
                      <flat-pickr v-model="searchParam.postEnDttm" :config="config" ref="dateComp2" class="txt_input jsCalendar" :tabindex="tabindex+5" @keydown="doSearchKeywordEnter"/>
                      <a class="cal_btn" data-toggle>
                        <img src="@/assets/img/02preview/cal_btn.gif" :alt="$t('endSelect')">
                      </a>
                    </div>
                  </div>
                </td>
                <td rowspan="2" style="width: 280px;" align="left">
                  <input type="submit" :value="$t('srch')" class="exhbitSearch ml10" @click="doSearch()" :tabindex="tabindex+9">
                </td>
              </tr>
              <tr v-if="isSearchDiv()">
                <td align="right">
                  <div class="search_put">
                    <div>
                      <!-- 그룹 -->
                      <select v-if="isTabList" v-model="searchParam.grpCd" class="selectFieldCd" :style="!isSearchList?'width: 272px':''" :tabindex="tabindex+6">
                        <template v-for="(item, index) in grpList">
                          <option v-if="doSelect(item,index, 'grpCd')" :selected="true" :value="item.grpCd" :key="`sel_${index}`">{{item.grpNm}}</option>
                          <option v-else :value="item.grpCd" :key="`nosel_${index}`">{{item.grpNm}}</option>
                        </template>
                      </select>

                      <!-- 검색컬럼코드 -->
                      <select v-if="isSearchList" v-model="searchParam.fieldCdNm" class="selectFieldCd ml10" :style="!isTabList?'width: 272px;':''" :tabindex="tabindex+7">
                        <template v-for="(item, index) in searchList">
                          <option v-if="doSelect(item,index, 'fieldCd')" :selected="true" :value="item.fieldCdNm" :key="`sel_${index}`">{{item.fieldNm}}</option>
                          <option v-else :value="item.fieldCdNm" :key="`nosel_${index}`">{{item.fieldNm}}</option>
                        </template>
                      </select>

                      <!-- 검색키워드 -->
                      <input v-if="isSearchList" v-model="searchParam.keyword" type="text" class="txt_input ml10" :placeholder="$t('srchInput')" :style="!isTabList?'margin-left: 38px;':''" @keydown="doSearchKeywordEnter" :tabindex="tabindex+8">
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- 리스트 영역 -->
        <div class="event_list" v-if="!isSearchLoading">
          <template v-if="dataItemList.length > 0">
            <div class="area" v-for="(item, index) in dataItemList" :key="index">
              <!-- 썸네일 -->
              <div class="pic_w">
                <p class="pic">
                  <router-link :to="doLink(item)" :tabindex="tabindex+index+10">
                    <img :src="item.imgUrl == null ? '' : encodeURI(item?.imgUrl)" @error="doImageError">
                  </router-link>
                </p>
              </div>
              <!-- 내용 -->
              <div class="info_w">
                <div :class="`cate cate_b0${grpList.findIndex(e => e.grpCd == item.grpCd)}`" v-if="item.grpNm != null && item.grpNm != '' && grpSize > 0">{{ item.grpNm }}</div>
                <div class="title">
                  <router-link :to="doLink(item)" style="line-height: 1.4em;" :tabindex="tabindex+index+11">
                    {{ board.ndisYn == 'Y' && (board.ndisRequYn == 'Y' || item.ndisYn == 'Y') ?`${$t('ndisYn')}` : item.field03}}
                    <!-- 댓글 수 -->
                    <span v-if="item.commCnt > 0" class="reply_count">({{item.commCnt}})</span>
                    <!-- 비밀번호 -->
                    <img v-if="board.writeYn == 'Y' && board.pwYn == 'Y'" class="pl3" src="@/assets/img/board/nninc_simple/img/icon_key.gif" :alt="$t('scrPost')">
                    <!-- 첨부파일 -->
                    <img v-if="item.attcCnt > 0" class="pl5" src="@/assets/img/board/nninc_simple/img/icon_file.gif" :alt="$t('fileAttach')">
                    <!-- 신규 글 -->
                    <img v-if="item.newPost == 'Y'" class="pl5" src="@/assets/img/board/nninc_simple/img/icon_new.gif" :alt="$t('postNew')">
                  </router-link>
                </div>
                <div class="txt_info" v-if="item.field22 != null && item.field22 != ''" v-html="$sanitize(decodeHTML(item.field22), boardDefaults)"></div>
                <ul class="event_info">
                  <li>기간 : <span>{{ item.postSt }} ~ {{ item.postEn }}</span></li>
                  <template v-for="(item2, index2) in headerList">
                    <!-- 제목, 요약글 제외 -->
                    <li v-if="item2.key != 'field03' && item2.key != 'field22' && item[item2.key] != null && item[item2.key] != ''" :key="index2">
                      <template v-if="item2.type != 'CMS003.ll'">
                        {{ item2.label }} : 
                      </template>

                      <!-- 입력 종류: 태그,에디터 -->
                      <template v-if="item2.type =='CMS003.t' || item2.type =='CMS003.e' || item2.type =='CMS003.ta' || item2.type == 'CMS003.i'"> 
                        <span v-html="$sanitize(item[item2.key], boardDefaults)"></span>
                      </template>
                      <!-- 입력 종류: 리스트 -->
                      <template v-else-if="item2.type == 'CMS003.l'">
                        <ul class="list2">
                          <li v-for="(listItem, listIndex) in item[item2.key]" :key="listIndex">
                            <strong>{{ item2.fieldData.split(',')[listIndex]}} : </strong> {{listItem}}
                          </li>
                        </ul>
                      </template>
                      <!-- 입력 종류: 링크리스트 -->
                      <span v-else-if="item2.type == 'CMS003.ll'">
                        <boardLinkButton v-model="item[item2.key]" :fieldData="item2.fieldData" :tabindex="tabindex+index+12"/>
                      </span>
                      <!-- 입력 종류: 체크박스  -->
                      <span v-else-if="item2.type == 'CMS003.c'">
                        <span v-for="(listItem, listIndex) in item[item2.key]" class="badge" :key="listIndex">{{ listItem }}</span>
                      </span>
                      <!-- 입력 종류: 날짜 -->
                      <span v-else-if="item2.type == 'CMS003.d'">
                        {{ doDateFormat(item[item2.key]) }}
                      </span>
                      <!-- 입력 종류: 일시 -->
                      <span v-else-if="item2.type == 'CMS003.dt'">
                        {{ doDateTimeFormat(item[item2.key]) }}
                      </span>
                      <!-- 입력 종류: 기간 -->
                      <span v-else-if="item2.type == 'CMS003.p'">
                        {{ doDateFormat(item[item2.key][0]) }} ~ {{ doDateFormat(item[item2.key][1]) }}
                      </span>
                      <!-- 입력 종류: 주소 -->
                      <span v-else-if="item2.type == 'CMS003.a'">
                        <template v-for="item in item[item2.key]">
                          {{item}}
                        </template>
                      </span>
                      <template v-else>
                        <span>{{ item[item2.key] }}</span>
                      </template>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </template>
          <div v-else class="empty">{{$t('empPost')}}</div>
          <!-- 버튼 -->
          <div class="board_button">
            <div class="fr pt10">
              <template v-if="board.writeYn == 'Y'">
                <span>
                  <router-link :to="doWrite()" class="cbtn cbtn_point" :tabindex="tabindex+13">{{$t('write')}}</router-link>
                </span>
              </template>
            </div>
          </div>
          <!-- 페이징 -->
          <pagination :pagingInfo="pagingInfo" :currentPage="searchParam.currentPage" v-model="currentPageIdx" :tabindex="tabindex+14"/>
        </div>
        <div v-else>
          <overlay></overlay>
        </div>
      </div>
    </div>
    <div v-else>
      <overlay v-if="!isContent"></overlay>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Korean} from 'flatpickr/dist/l10n/ko.js';

import HDate from '@/libs/date';
import { boardDefaults } from '@/libs/sanitize'
import {decodeHTMLEntities} from '@/libs/comm/util'

import childTab from '@/views/component/ChildTab';
import pagination from '@/views/component/Pagination';
import boardLinkButton from '@/views/component/BoardLinkButton';
import overlay from "@/views/component/Overlay";

export default {
  components: {
    flatPickr,
    childTab,
    pagination,
    boardLinkButton,
    overlay,
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    grpList: {
      type: Array,
      default: () => []
    },
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  computed:{
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    }
  },
  watch: {
    currentPageIdx: {
      handler(newVal, oldVal) {   
        const firData=(newVal??1) == 0?1:newVal??1;
        const seconData=(oldVal??1) == 0?1:oldVal??1;
      
        if(firData != seconData) {
          this.doPage(firData)
        }
      }
    },
    'board.boardNo'(newVal, oldVal){
      if(newVal != oldVal) {
        this.setSearchParam();
        this.subContentNo = null;
        if(this.board.grpDispCd == 'CMS009.select' && this.grpList != null && this.grpSize > 0) {
          this.isTabList = true;
        }

        if(this.grpList != null && this.grpSize > 0){
          // 그룹을 사용하지 않거나 그룹을 사용하고 있지만 비공개 설정을 사용할 경우 그냥 조회
          if(this.isTabList || this.board.grpYn != 'Y' || this.board.ndisYn == 'Y' ) {
            const query = {...this.$route.query};

            this.searchParam.grpCd = query[`s${this.depth}`];
            this.doList();
          }else{
            this.setCurrentTab();
          }
        } else {
          this.doList();
        }
      }
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.setSearchParam();
          this.currentPageIdx = newVal.page;
          
          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab();
          }

          // 컨텐츠유형 : 게시판 
          if(this.board.grpYn == 'N' && this.searchParam.grpCd == null || this.searchParam.grpCd == '') {
            if(newVal[`s${this.depth-1}`] == oldVal[`s${this.depth-1}`]) {
              // 탭 변경 이외 조회가능하게 
              this.doList()
            } 
          }else{
            // 컨텐츠유형 : 분류
            this.doList();
          }
        }
      }
    }
  }, 
  created() {
    const grpYn = this.board?.grpYn;
    const grpDispCd = this.board?.grpDispCd;
    const ndisYn = this.board?.ndisYn;

    const initPage = this.$route?.query?.page;

    if(initPage != null && initPage != ''){
      this.currentPageIdx = initPage
    }

    this.setSearchParam();

    if(this.$route.name.indexOf('direct') == 0) {
      this.routeDiv = 'direct'
    }

    //분류가 존재하는경우 분류를 통한 조회
    if(this.grpList != null && this.grpSize > 0 && grpDispCd != null && grpDispCd != '' && grpYn == 'Y') {
      if(ndisYn == 'Y' || grpDispCd == 'CMS009.select'){
        // 게시물 상세에서 뒤로가기 진행시 게시판 설정이 비공개이거나 선택상자 사용시 탭 세팅 안하고 바로 조회
        this.doList();
      }else{
        this.setCurrentTab()
      }
    }else{
      const boardNo = this.board?.boardNo;

      if(boardNo != null && boardNo != '') {
        // 글쓰기 및  상세 & 뒤로가기 할 때 조회 되게 
        this.doList();
      }
    }

    const hdate = new HDate();

    this.config = {
      altInput: true,
      dateFormat: "yyyyMMdd",
      altFormat: "yyyy-MM-dd",
      allowInvalidPreload: true,
      clickOpens: false,
      locale: Korean, // locale for this instance only   
      parseDate: (datestr, format) => {
        let res = null;
        if(datestr != null ){
          const regex = /[^0-9]/g;
          const result = datestr.replace(regex, "");
          res = hdate.strToDate(result, format);
        }
        return res;
      },
      formatDate: (date, format, locale) => {
        // locale can also be used
        return hdate.getMaskFormatString(date,format);
      },
      allowInput: true,
      wrap: true,
    }
  },
  mounted() {
    const dateComp = this.$refs.dateComp

    if(dateComp != null && dateComp != '') {
      this.$refs.dateComp.fp.set('allowInput', this.config.allowInput)
      this.$refs.dateComp.fp.altInput.readOnly= !this.config.allowInput
    }
  },
  data() {
    return {
      config:{},
      searchParam: {
        contentNo: '',
        currentPage: 1,
        pagePerNo: null,
        fieldCdNm: null,
        grpCd: null,  
        keyword: '',
        postStDttm: null,
        postEnDttm: null,
        vtime: '1',
      },
      headerList: [],
      searchList: [],
      pagingInfo: {
        totalCnt: 0,
        firstPage: 0,
        lastPage: 0,
        listItemNo: 0,
        nextPage: 0,
        prevPage: 0,
        totalPage: 0,
      },
      currentPageIdx: 1,
      dataItemList: [],
      currentTab: 'none',
      fileInfo: {
        fileComplet: null,
        fileSize: '5242880', 
        fileExt: ['hwp', 'doc', 'ppt', 'xls', 'txt', 'gif', 'jpg', 'docx', 'pptx', 'xlsx', 'zip', 'alz', 'rar', 'pdf'],
        fileCnt: 2,
        multiple: false,
        fileDownloadNm: this.$t('attcFile'),
      },
      isTabList: false,
      isSearchList: false,
      isContent: false,
      subContentNo: '',
      subContentType: 'A',
      routeDiv: 'dynamic',
      isSearchLoading: false,
      boardDefaults
    }
  },
  methods: {
    //기본파라미터 세팅
    setSearchParam() {
      const query = this.$route.query;
      const searchCD = query?.searchCD;
      const grpYn = this.board?.grpYn;
      const grpCd = query[`s${this.depth}`];
      const page = parseInt(query?.page);
      const pagePerNo = query?.pagePerNo;
      const vtime = query?.vtime;
      const ndisYn = this.board.ndisYn;
      const ndisRequYn = this.board.ndisRequYn;
      
      if(grpYn == 'Y' && this.grpSize > 0) {
        if(ndisYn == 'Y' && ndisRequYn == 'Y') {
          this.searchParam.grpCd = null;
        }else{
          this.searchParam.grpCd = grpCd;
        }
      }
      
      if(vtime == null || vtime =='') {
        this.searchParam.vtime = '1';
      }else{
        this.searchParam.vtime = vtime;
      }

      this.searchParam.fieldCdNm = searchCD;

      if(pagePerNo != null && pagePerNo != '') {
        this.searchParam.pagePerNo = pagePerNo;
      }else{
        this.searchParam.pagePerNo = this.board.pagePerNo;
      }

      this.searchParam.postStDttm = query?.postSt;
      this.searchParam.postEnDttm = query?.postEn;
      this.searchParam.currentPage = page > 0 ? page:1;
      this.searchParam.keyword = query?.keyword;
      this.searchParam.contentNo = this.board.boardNo;
      this.searchParam.boardTypeCd = this.board.boardTypeCd;
      this.searchParam.newIconDispDd = this.board.newIconDispDd;

      if(this.board.attcYn == 'Y') {
        this.fileInfo = {
          fileComplet: null,
          fileSize: this.board.attcLimitSize * 1048576, 
          fileExt: this.board.attcAllowExts,
          fileCnt: this.board.attcLimitNo,
          multiple: true,
          fileDownloadNm: this.$t('attcFile'),
        }
      }   
    },
    setMetaInfo() {
      const query = this.$route.query;
      // 현재 뎁스의 그룹코드
      const grpCd = query[`s${this.depth}`];
      // 현재 URL
      const url = document.location.href;
      // 게시판 이름
      const boardNm = this.board.boardNm;
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 그룹리스트
      const grpList = this.grpList;

      const metaInfoData = {
        title: boardNm,
        ogTitle: boardNm,
        ogUrl: url,
        ogDescription: boardNm,
        ogImage: '/logo.png',
      };
      
      if(grpList != null && this.grpSize > 0 && grpYn == 'Y') {
        const curTabData = grpList.find(e => e.grpCd == grpCd);

        if(curTabData != null) {
          const grpNm = curTabData.grpNm;
          if(grpNm != null && grpNm != '') {
            metaInfoData.title = `${this.$t('nationalSj')}: ${boardNm} - ${grpNm}`;
          }
        }
      }else{
        metaInfoData.title = `${this.$t('nationalSj')}: ${boardNm}`;
      }

      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
    isTab(){
      // 그룹 탭 리스트 
      const grpList = this.grpList;
      // 그룹 사용 컴포넌트 코드(탭 / 선택상자)
      const grpDispCd = this.board.grpDispCd;
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 비공개 여부
      const ndisYn = this.board.ndisYn;
      // 비공개 필수 여부
      const ndisRequYn = this.board.ndisRequYn;

      if(grpList == null || this.grpSize < 1){
        return false;
      }

      if(grpYn != 'Y' || grpDispCd != 'CMS009.tab'){
        return false;
      }

      if(ndisYn == 'Y' && ndisRequYn == 'Y'){
        return false;
      }

      return true;
    },
    isSearchDiv() {
      const grpDispCd = this.board.grpDispCd;
      // 비공개 필수 여부
      const ndisRequYn = this.board.ndisRequYn;
      // 검색조건 선택 리스트
      const searchList = this.searchList;
      // 해당 게시판 그룹리스트(선택상자인 경우)
      const grpList = this.grpList;
      
      if(ndisRequYn == 'Y'){
        return false;
      }
      
      if(searchList == null || searchList.length < 1){
        this.isSearchList = false;
      }else{
        this.isSearchList = true;
      }

      if(grpDispCd == 'CMS009.tab' || grpList == null || this.grpSize < 1) {
        this.isTabList = false;
      }else{
        this.isTabList = true;
      }

      //검색조건이 없는경우 제외 하지만 그룹코드가 존재하는 경우 검색조건 활성화
      if(!this.isTabList && !this.isSearchList){
        return false;
      }
      
      return true;
    },
    changeTab(data, contentType, key, isSameAllow=false) {
      const query = {...this.$route.query};
      query[`s${this.depth}`] = key;
      let isSearch = true;
      //주소가 같이 않으면주소 변경
      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
        
        if(contentType != 'A') {
          this.searchParam.grpCd = data
        }else{
          this.searchParam.contentNo = data
        }

        delete param.query.page;
        this.searchParam.currentPage = 1;

        isSearch = false
        this.$router.push(param);
      }

      //컨텐츠가 분류가아니면 하위 컴포넌트 조회
      if(contentType != 'A'){
        //하위컨텐츠의 타입 전달
        this.subContentType = contentType
        this.isContent = true;
        this.subContentNo = data
      }else{
        this.isContent = false;
        this.subContentNo = '';
        //그룹코드를 통한 데이터 조회를 위한 설정
        if(this.searchParam.grpCd == null || this.searchParam.grpCd == '') {
          if(this.board.ndisRequYn == 'Y') {
            this.searchParam.grpCd = null
          }else{
            this.searchParam.grpCd = data
          }
        }

        if(isSearch){
          this.doList()
        }
      }
    },
    setCurrentTab() {
      const isFirst = this.$route.params.isFirst
      
      if(isFirst != null&& isFirst != ''){
        const grpList = this.grpList
        if(grpList != null && this.grpSize > 0){
          const firstGrpList = grpList[0]
          const grpCd = firstGrpList.grpCd
          const contentType = firstGrpList.contentType
          const linkUrl = firstGrpList.linkUrl
          if(this.currentTab == null){
            this.changeTab(contentType!='A'?linkUrl:grpCd,contentType,grpCd)
          }else{
            this.currentTab = grpCd
          }
        }
      }else{
        this.currentTab = this.$route.query[`s${this.depth}`]
      }
    },
    doSearchKeywordEnter(event) {
      if(event.keyCode == 13) {
        this.doSearch();
      }
    },
    doList() {
      this.isSearchLoading = true;
      this.$tran('/site/external/contents/selectPostImgList', this.searchParam).then(response => {
        this.headerList = response.data.headerList
        this.searchList = response.data.searchList
        this.dataItemList = response.data.postList
        this.pagingInfo = response.data.pagingInfo
        this.rows = response.data.rows;
        this.setMetaInfo();
      }).finally(() => {
        this.isSearchLoading=false
      })
    },
    doLink(data) {
      const query = {...this.$route.query}
      
      query['postNo'] = data.postNo
      
      if(this.board.grpYn == 'Y') {
        query[`s${this.depth}`] = this.searchParam.grpCd
      }
      
      return {name: `${this.routeDiv}DetailExhbit`, query: query} 
    },
    doWrite() {
      const query = {...this.$route.query}

      return {name:`${this.routeDiv}Modify`, query: query, params: {depth: this.depth}}
    },
    doSelect(item, index, type) {
      if((this.searchParam.fieldCdNm == null || this.searchParam.fieldCdNm == '') && type == 'fieldCd') {
          if(index == 0){
            this.searchParam.fieldCdNm=item.fieldCdNm
          return true
        }
      }else if((this.searchParam.grpCd == null || this.searchParam.grpCd == '') && type == 'grpCd') {
        if(index == 0){
          this.searchParam.grpCd=item.grpCd
          return true
        }
      }else{
        return false
      }
    },
    doPage(index) {
      const query = {...this.$route.query};
      const currentPage = index!=null?index:1; 

      this.searchParam.currentPage=currentPage;
      this.currentPageIdx=currentPage;
      query.page = currentPage;

      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
    
        this.$router.push(param);
      }
    },
    doSearch() {
      const query = {...this.$route.query};
      // 검색 코드
      const searchCD = this.searchParam.fieldCdNm;
      // 검색어
      const keyword =  this.searchParam.keyword;
      // 그룹 
      const grpCd = this.searchParam.grpCd;
      // 페이징 수
      const pagePerNo = this.searchParam.pagePerNo;
      // 그룹표시 코드
      const grpDispCd =  this.board.grpDispCd;
      // 게시시작일 
      const postSt = this.searchParam.postStDttm;
      // 게시종료일 
      const postEn = this.searchParam.postEnDttm;

      if(grpDispCd == 'CMS009.select') {
        query[`s${this.depth}`] = grpCd;
      }

      if(pagePerNo != null && pagePerNo != '') {
        query.pagePerNo = pagePerNo;
      }
      
      if(keyword != null && keyword != '') {
        query.searchCD = searchCD;
        query.keyword = keyword;
      }else{
        delete query.searchCD;
        delete query.keyword;
      }

      if(postSt != null && postSt != '') {
        query.postSt = postSt;
      }else{
        delete query.postSt;
      }

      if(postEn != null && postEn != '') {
        query.postEn = postEn;
      }else{
        delete query.postEn;
      }

      delete query.page;
      this.searchParam.currentPage=1;

      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
        this.$router.push(param);

        this.doList()
      }
    },
    changeTimeTab(vtime) {
      const query = {...this.$route.query};
      query.vtime = vtime;
      this.searchParam.vtime = vtime;

      //주소가 같이 않으면주소 변경
      if(JSON.stringify(this.$route.query) != JSON.stringify(query)) {
        const param = {query};
    
        this.$router.push(param);
      }
    },
    doImageError(e) {
      e.target.src = require('@/assets/img/board/errorImage.jpg')
    },
    doDateFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'yyyy-MM-dd');
      }

      return formatDate
    },
    doDateTimeFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'yyyy-MM-dd HH:mm:ss');
      }

      return formatDate
    },
    decodeHTML(str) {
      if(str != null && str != '') {
        return decodeHTMLEntities(str)
      }
      return
    },
    doKeydownTimeTab(event, vtime) {
      if(event.keyCode == 13) {
        this.changeTimeTab(vtime);
      }
    }
  },
}
</script>