<template>
  <div id="wrapper" class="sub" style="height: 100%;">
    <div id="head">
      <LayoutLinkTop :tabindex="100" :topLinkList="topLinkList"/>
      <LayoutTop @onMobileMenu="onMobileMenu" :tabindex="200"/>
    </div>
    <div id="container" :style="$route.path !='/intro'?{top:0}:''"> 
      <!-- 배너: 메인상단 -->
      <template v-if="showTopBanner">
        <a :href="topBanner.url" :target="topBanner.newWinYn=='Y'?'_blank':'_self'">
          <div :style="doBannerStyle(topBanner)">
            <div v-html="$sanitize(topBanner.content)"></div> 
          </div>
        </a>
        <div class="title">
          <a class="topBannerClose" @click="doBannerClose()"></a>
        </div> 
      </template>

      <!-- 모바일메뉴 전체보기 -->
      <transition name="slide">
        <div id="rightMenu" v-show="isShowMobile" :style="$route.path =='/intro'?{top:'-80px'}:''">
          <div class="totalwrap">
            <div class="total">
              <nav class="menu">
                <h1 class="logo">
                  <a href="/">국립세종수목원</a>
                </h1>		
                <a class="total_close" @click="onMobileMenu()">
                  <img src="@/assets/img/common/img/mmenu_close.png" alt="전체메뉴닫기">
                </a>
              </nav>
              <div class="total_mn_w">
                <div class="total_mn">
                  <!-- 1뎁스 메뉴 반복 -->
                  <template v-for="(item, index) in depth1All">
                    <li v-if="item.hiddenYn != 'Y'" :key="index" :class="setMoblieMenuClass(item, index)">
                      <a v-html="$sanitize($t(item.mulLangCd))" @click="doMobileMnClick(item)"></a>
                    </li>
                  </template>
                </div>
              </div>
              <div class="total_submn">
                <div id="subcon" ref="total_submn" class="subcon mscrollX" @touchmove="doMobileMnTouchmove()">
                  <template v-for="(item, index) in depth1All">
                    <template v-if="item.hiddenYn != 'Y'">
                      <dl v-if="item.hiddenYn != 'Y'" :key="index">
                        <dt class="icon1" :ref="item.menuId">
                          <span v-html="$sanitize($t(item.mulLangCd))"></span>
                        </dt>
                        <template v-for="(subItem, subIndex) in depth2(item)">
                          <dd v-if="subItem.hiddenYn != 'Y'" :key="subIndex">
                            <a @click="depth2Link(subItem)" v-html="$sanitize($t(subItem.mulLangCd))"></a>
                          </dd>
                        </template>
                      </dl>
                    </template>
                  </template>  
                  <dl class="lastdl" style="height: 623px;"></dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      
      <!-- 메인화면일 경우 사이드바 비활성화 -->
      <LayoutSidebar v-if="$route.path !='/intro'" :tabindex="300"/>
      <template>
        <div id="contents" v-if="$route.path !='/intro'">
          <!-- 상단 메뉴 정보 -->
          <div id="cont_head">
            <h2>{{$t(curMenuNm)}}</h2>
          </div>
          <div id="cont_wrap">
            <router-view :tabindex="400"/>
          </div>
        </div>
        <template v-else>
          <router-view :tabindex="400"/>
        </template>
      </template>
      <!-- 퀵영역 -->
      <div class="go-top" :class="goTopClass">
        <p class="top_btn">
          <a @click="doScrollTop()" @keydown="doKeydownScrollTop($event)" :tabindex="8999">
            <img src="@/assets/img/skin01/img/common/quick_top.png" :alt="$t('moveTop1')"/>
          </a>
        </p>
      </div>
      <ul ref="quickLink" class="quick_link" :style="quickLinkStyle">
        <li class="quick1">
          <a @click="doContentLink('M0005', 'M0001')" @keydown="doKeydownContentLink($event, 'M0005', 'M0001')"  tabindex="8000">{{$t('infoGuide')}}</a>
        </li>
        <li class="quick2">
          <a @click="doContentLink('M0006', 'M0001')" @keydown="doKeydownContentLink($event, 'M0006', 'M0001')" tabindex="8001">{{$t('directions1')}}</a>
        </li>
        <li class="quick3">
          <a @click="doContentLink('M0007', 'M0002')" @keydown="doKeydownContentLink($event, 'M0007', 'M0001')" tabindex="8002">{{$t('collecGuide')}}</a>
        </li>
        <li class="quick6">
          <a href="https://reserve.koagi.or.kr/main.do" target="_blank" tabindex="8003">{{$t('reserv')}}</a>
        </li>
        <li class="quick5">
          <a @click="doContentLink('M0016', 'M0009', '4368')" @keydown="doKeydownContentLink($event, 'M0016', 'M0009', '4368')" tabindex="8004">{{$t('findThing')}}</a>
        </li>
      </ul>
    </div>
    <LayoutFooter :tabindex="9000"/> 
    <!-- 팝업: 브라우저팝업 -->
    <template v-if="$route.path =='/intro'">
      <template v-for="(item, index) in layerPopupList">
        <div v-if="doCookie(item)" class="mmimg_w" :style="item.layerStyle != null? item.layerStyle :doLayerStyle(item)" :key="index">
          <div class="mmimg_in" :style="`height: ${item.heightPixel}px`">
            <div v-html="item.content"></div>
          </div>
          <div class="today-close">
            <ul>
              <input type="checkbox" v-if="item.nextCloseYn == 'Y'" v-model="item.layerNextCloseYn" true-value="Y" false-value="N" tabindex="1">
              <label for="next_close" v-if="item.nextCloseYn == 'Y'">{{ item.nextCloseDd }}{{$t('nextCloseDdText')}}</label>
              <input type="button" value="닫기" @click="doNextClose(item, item.layerNextCloseYn)" class="ct_bt01" tabindex="1">
            </ul>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LayoutTop from '@/views/layouts/LayoutTop'
import LayoutFooter from '@/views/layouts/LayoutFooter'
import LayoutLinkTop from '@/views/layouts/LayoutLinkTop'
import LayoutSidebar from '@/views/layouts/LayoutSidebar'
import { mapGetters } from 'vuex'

// import iScroll from 'iscroll'

export default {
  metaInfo() {
    return {
      title: this.metaInfo.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: this.metaInfo.ogTitle }, 
        { property: 'og:url', content: this.metaInfo.ogUrl },
        { property: 'og:description', content: this.metaInfo.ogDescription },
        { property: 'og:image', content: this.metaInfo.ogImage },
      ]
    }
  },
  components: {
    LayoutTop,
    LayoutFooter,
    LayoutLinkTop,
    LayoutSidebar,
  },
  computed: {
    ...mapGetters([
      'metaInfo',
      'depth1All',
      'depth2All',
      'depth3All',
      'depth4All',
      'curMenuNm',
    ]),
    depth2() {
      return (depth1) => {
        if(this.depth2All != null) {
          return this.depth2All.filter((e) => {
            return e.seqChar.indexOf(depth1.menuId) > -1
          })
        }else {
          return []
        }
      }
    },
    depth3() {
      return (depth2) => {
        if(this.depth3All != null) {
          return this.depth3All.filter((e) => {
            return e.seqChar.indexOf(depth2.menuId) > -1
          })
        }else{
          return []
        }
      }
    }, 
  },
  watch: {
    '$route.name': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && newVal != oldVal) {
          this.windowE()
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.windowE)
    window.removeEventListener('resize', this.windowE)
    window.removeEventListener('load', this.windowE)
  },
  mounted() {
    window.addEventListener('scroll', this.windowE)
    window.addEventListener('resize', this.windowE)
    window.addEventListener('load', this.windowE)
  },
  created() {
    this.doMainContent();
  },
  data() {
    return {
      goTopClass: '',
      quickLinkStyle: { top: '215px'},
      topBanner: {},
      showTopBanner: false,
      layerPopupList: [],
      windowPopupList: [],
      popCnt: 0,
      layerNextCloseYn: 'N',
      isShowMobile: false,
      // rightMenuStyle: {display: 'none'},
      // wrapperStyle: {height: '100%'},
      mobileMenuClass: null,
      topLinkList:[],
      snsLinkList:[],
    }
  },
  methods: {
    // 스크롤 이동시 상단으로 이동하는 Top 아이콘 class set
    windowE(){
      const scrollEvt = window.scrollY;	

      if(scrollEvt >= 50) {
        this.goTopClass = 'show';
      }else{
        this.goTopClass = '';
      }

      if(this.$route.path == '/intro') {
        this.quickLinkStyle.top='190px';  
      }else{
        this.quickLinkStyle.top='100px'; 
      }
      
      this.doQuickLink();
    },
    // 스크롤 최상단으로 이동
    doScrollTop() {
      window.scrollTo({top:0, behavior:'smooth'});
    },
    // 퀵영역 : 스크롤 높이에 따른 처리
    doQuickLink() {
      const scrollEvt = window.scrollY;	
      const currentPosition = this.quickLinkStyle.top.replace('px','');
      const quickLink = parseInt(scrollEvt)+parseInt(currentPosition);
      
      this.quickLinkStyle.top = quickLink+'px'

      this.$refs.quickLink.animate({"top": quickLink+'px'},500);
    },
    // 메인컨텐츠 조회
    doMainContent() {
      this.$tran('/site/external/main/selectMainContent', '').then(response => {
        this.topBanner = response.data.topList[0];
        this.topLinkList = response.data.topLinkList;
        this.layerPopupList = response.data.layerPopupList;
        this.windowPopupList = response.data.windowPopupList;
        this.popCnt = this.layerPopupList.length;
        this.$nextTick(() => {
          const bannerNo = this.topBanner?.bannerNo;
          if(bannerNo != null && bannerNo != '') {
            this.showTopBanner = true;
          }

          if(this.windowPopupList != null && this.windowPopupList.length > 0 && this.$route.path =='/intro') {
            this.doWindowPopup();
          }
        })
      })
    },
    // 컨텐츠 링크
    doContentLink(menuId, upMenuId, grpCd) {
      this.$router.push({name:'content', query: {menuId: menuId, upMenuId: upMenuId, s0: grpCd}})
    },
    // 브라우저 팝업 닫기(다음닫기처리)
    doNextClose(item, layerNextCloseYn) {
      this.popCnt = this.popCnt - 1;

      if(layerNextCloseYn == 'Y') {
        this.$cookies.set(item.popupNo, 'Y', `${item.nextCloseDd}d`);
      }
      
      this.doLayerClose(item);
    },
    // 브라우저 팝업 닫기(style 처리)
    doLayerClose(item) {
      item.layerStyle.display = 'none';
    },
    // 브라우저팝업 스타일 set
    doLayerStyle(item) {
      const imgUrl = item?.imgUrl;
      const width = item.widthPixel;
      const height = item.heightPixel;
      const left = item.leftPixel;
      const top = item.topPixel;

      const layerStyle = {
        backgroundImage: `url(${encodeURI(imgUrl)})`,
        width: `${width}px`,
        height: `${Number(height)+40}px`,
        left: `${left}px`,
        top: `${top}px`,
        display: 'block',
        backgroundColor: 'rgb(255, 255, 255)',
        whiteSpace: 'normal',
        border: '1px solid rgb(204, 204, 204)',
        position: 'absolute',
        zIndex: '999',
        backgroundRepeat: 'no-repeat'
      }

      this.$set(item, 'layerStyle', layerStyle);
    },
    // 팝업 쿠키처리(레이어, 새창)
    doCookie(item) {
      const data = this.$cookies.get(item.popupNo);

      if(data != null) {
        return false;
      }
      return true;
    },
    // 메인상단배너 style set 
    doBannerStyle(item) {
      const imgUrl = item?.imgUrl;
      const bannerStyle = {
        backgroundImage: `url(${encodeURI(imgUrl)})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
        position: 'relative',
        zIndex: '998',
        overflow: 'inherit',
      }
      return bannerStyle;
    },
    // 메인상단배너 닫기
    doBannerClose() {
      this.showTopBanner = false;
    },
    onMobileMenu() {
      if(!this.isShowMobile) {
        // this.wrapperStyle.height='100%';
        this.isShowMobile = true;
      }else{
        // this.wrapperStyle.height='100%';
        this.isShowMobile = false;
      }
    },
    setMoblieMenuClass(item, idx) {
      let menuClass = '';

      if(this.mobileMenuClass == null || this.mobileMenuClass == '') {
        if(idx == 0) {
          this.mobileMenuClass = item.menuId;
        }
      }

      if(this.mobileMenuClass == item.menuId) {
        menuClass = 'on'
      }

      return menuClass
    },
    doMobileMnClick(item) {
      if(item != null) {
        this.mobileMenuClass = item.menuId;
        this.$refs.total_submn.scrollTo({top:this.$refs[item.menuId][0].offsetTop,behavior:'smooth'});
      }
    },
    doMobileMnTouchmove() {
      this.depth1All.forEach((value, index) => {
        const menuId = value.menuId;
        const menuDiv = this.$refs[menuId];

        if(menuDiv != null) {
          const menuRect = menuDiv[0].getBoundingClientRect();
          const menuTop = menuRect.top;

          if(menuTop <= 76) {
            this.mobileMenuClass = menuId
          }
        }
      }) 
    },
    // 2뎁스 링크
    depth2Link(depth2) {
      let menuTypeCd = null;
      const param = {};
      let item = {};

      let depth3MenuList = this.depth3(depth2).filter(e=> e.hiddenYn == 'N');

      if(depth3MenuList != null && depth3MenuList.length > 0) {
        // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
        const curDepth3 = depth3MenuList[0];
        item = depth3MenuList[0];
        menuTypeCd = curDepth3.menuTypeCd;
        param.query = {menuId: curDepth3.menuId, upMenuId: curDepth3.upMenuId, s0:curDepth3.firstGrpCd};  
      }else{
         // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
        menuTypeCd = depth2.menuTypeCd;
        item = depth2;
        param.query = {menuId: depth2.menuId, upMenuId: depth2.upMenuId, s0:depth2.firstGrpCd}; 
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);
      param.params = {isFirst: item.menuId};

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenuNm', item.mulLangCd);
        this.$router.push(param)
      }

      // this.wrapperStyle.height='100%';
      this.isShowMobile = false;
    },
    // 새창팝업 띄우기
    doWindowPopup() {
      for(let i=0; i < this.windowPopupList.length; i++) {
        const getCookie = this.doCookie(this.windowPopupList[i]);

        if(getCookie) {
          const data = this.windowPopupList[i]
          const popupNo = data.popupNo;
          const imgUrl = data.imgUrl;
          const width = data.widthPixel;
          let height = data.heightPixel;
          const left = data.leftPixel;
          const top = data.topPixel;
          const content = data.content;
          const nextCloseDd = data.nextCloseDd;
          const nextCloseYn = data.nextCloseYn;
          
          if(height != null && typeof height == 'string'){
            const intData = height.replaceAll(/(-)[^\d.]/gi, '')
            if(intData != null && intData != ''){
              height = (parseFloat(intData) + 30) + height.replaceAll(/[-\d.]/gi,'')
            }
          }
          
          const win = window.open('', popupNo, `location=no,width=${width},height=${height},top=${top},left=${left}`);

          if(win.document.querySelector('.newPopupDiv') != null) {
            win.document.querySelector('.newPopupDiv').remove();
            win.document.querySelector('.popup').remove();
          }

          if(win.document.querySelector('.footer') != null) {
            win.document.querySelector('.footer').remove();
          }

          if(win.document.querySelector('script') != null) {
            win.document.querySelector('script').remove();
          }

          if(win.document.querySelector('style') != null) {
            win.document.querySelector('style').remove();
          }
          
          const htmlTag = `
                    <style>
                      body {
                        margin: 0px;
                      }

                      img {
                        width: 100%;
                      }

                      p br{
                        display:none;
                      }

                      table, pre,div {
                        color: #575757; 
                        font-size: 1em;
                      }

                      .newPopupDiv {
                        height: 100%;
                        width: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-image: url(${encodeURI(imgUrl)});
                      }

                      #next_close {
                        margin-top: 9px;
                      }

                      div.popup {
                        clear: both;
                        position: relative;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: right;
                      }

                      div.popup .ct_bt01 {
                        margin-right:10px;
                        margin-left:10px;
                      }

                      @media(max-width: 736px) {
                        .mmimg_w {
                          width:100% !important;
                          height: calc(100% - 30px) !important;
                          top: 0 !important;
                          left: 0 !important;
                        }

                        .mmimg_w, .mmimg_in, .mmmimg{
                          width: 100% !important;
                          height: calc(100% - 30px) !important;
                        }

                        .mmmimg p, .mmmimg img {
                          width: 100% !important;
                          height: auto;
                        }
                      }
                  </style>
                  <div>
                    <div class="mmmimg newPopupDiv">
                      ${content==null?'':content}
                    </div> 
                    <div class="popup">
                      ${nextCloseYn=='Y'? `<input type="checkbox" id="next_close">${nextCloseDd} ${this.$t('nextCloseDdText')}`:''}
                      <input type="button" value="닫기" onClick="closeToday()" class="ct_bt01">
                    </div>  
                  </div> 
                  <script type="text/javascript">
                    function closeToday() {
                      ${nextCloseYn=='Y'? `var isNextClose = document.getElementById('next_close').checked;

                      if(isNextClose) {
                        var todayDate = new Date();
                        todayDate.setDate( todayDate.getDate() + ${nextCloseDd} );
                        document.cookie = name + "= Y" + "; path=/; expires=" + todayDate.toGMTString() + ";"
                      }
                      `:''}
                      window.close();
                    }
                  <\/script>`;
          win.document.write(htmlTag);
        }
      }
    },
    doKeydownContentLink(event, menuId, upMenuId, grpCd) {
      if(event.keyCode == 13) {
        this.doContentLink(menuId, upMenuId, grpCd);
      }
    },
    doKeydownScrollTop(event) {
      if(event.keyCode == 13) {
        this.doScrollTop();
      }
    }
  },
}
</script>
<style scoped>
.slide-enter-active {
  opacity: 1;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.slide-leave-active {
  opacity: 0;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
  left: 0px;
  right: 0px;
}

.slide-enter, .slide-leave-to {
  left: 100%;
  right: 0px;
}
</style> 