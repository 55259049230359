<template>
  <div id="board" style="width:100%;">
    <!-- 진행/종료 탭 고정 -->
    <div class="con_tab">
      <ul class="no2">
        <li :class="detailMain.postTeamOver =='N'?'on':''">
          <a @click="changeTimeTab('1')" :tabindex="tabindex+1">{{$t('ing')}}</a>
        </li>
        <li :class="detailMain.postTeamOver =='Y'?'on':''">
          <a @click="changeTimeTab('3')" :tabindex="tabindex+2">{{$t('terminate')}}</a>
        </li>
      </ul>
      <!-- 그룹 탭 -->
      <ChildTab v-if="isTab()" :grpList="grpList" v-model="currentTab" @changeTab="changeTab" :isSameAllow="true" :depth="depth" :tabindex="tabindex+3"/>
    </div>

    <br/>
    <template v-if="isPwChk=='N'">
      <div id="board" style="width:100%; margin-top: 20px;">
        <div class="pwd_check">
          {{$t('message.L000004')}}<br><br>
          <ul>
            <li>
              <label for="c_pwd" class="text">{{$t('pw')}} :</label> 
              <input type="password" size="15" :title="$t('pwInput')" v-model="searchParam.inputPw" @keydown="doPwEnter" class="board_input" style="vertical-align:middle;" autocomplete="off" :tabindex="tabindex+4">
              <img src="@/assets/img/board/nninc_simple/img/command_ok.gif" width="34" height="20" :alt="$t('confirm')" style="vertical-align:middle;padding-left:3px;" @click="doChkPw" @keydown="doPwEnter" :tabindex="tabindex+5"/>
            </li>
          </ul>
        </div>
        <div class="pwd_check_button">
          <span>
            <a @click="goBack" @keydown="doKeydownBack($event)" class="cbtn cbtn_g" :tabindex="tabindex+6">{{$t('back')}}</a>
          </span>
        </div>
      </div>
    </template>
    <div class="event_skin" v-if="isPwChk=='Y'">
      <!-- 디테일 영역 -->
      <div class="event_view"> 
        <div class="top_area"> 
          <div class="pic_w"> 
            <p class="pic">
              <img :src="encodeURI(imgMainView?.imgUrl)" @error="doImageError">
            </p>
          </div>
          <div class="info_w">
            <!-- 분류 -->
            <div :class="`cate cate_b0${grpList.findIndex(e => e.grpCd == detailMain.grpCd)}`" v-if="(detailMain.grpNm != null && detailMain.grpNm != '') && board.grpYn == 'Y' && grpSize > 0">
              {{ detailMain.grpNm }}
            </div>
            <!-- 제목 -->
            <div class="title">{{ detailMain.field03 }}</div>
            <ul class="event_info">
              <!-- 게시기간 -->
              <li v-if="(detailMain.postStDt != null && detailMain.postStDt != '') || (detailMain.postEnDt != null && detailMain.postEnDt != '')">기간 : <span>{{ detailMain.postStDt }} ~ {{ detailMain.postEnDt }}</span></li>
              <!-- 필드 리스트 -->
              <template v-for="(item, index) in fieldList">
                <li v-if="item.fieldCdNm != 'field03' && item.fieldCdNm != 'field22' && item.fieldCdNm != 'field08'" :key="index">
                  <template v-if="item.fieldType != 'CMS003.ll' && item.fieldCdNm != 'field21' && detailMain[item.fieldCdNm] != null && detailMain[item.fieldCdNm] != ''">
                    {{ item.fieldNm }} : 
                  </template>

                  <!-- 입력 종류: 체크박스 -->
                  <span v-if="item.fieldType=='CMS003.c'">
                    <span v-for="(listItem, listIndex) in detailMain[item.fieldCdNm]" class="badge" :key="`badge_${listIndex}`">{{ listItem }}</span>
                  </span>
                  <!-- 입력 종류: 태그,에디터 -->
                  <span v-else-if="item.fieldType ==  'CMS003.t' || item.fieldType ==  'CMS003.e' || item.fieldType =='CMS003.ta' || item.fieldType == 'CMS003.i'">{{ $sanitize(decodeHTML(detailMain[item.fieldCdNm]), boardDefaults) }}</span>
                  <!-- 입력 종류: 리스트 -->
                  <span v-else-if="item.fieldType == 'CMS003.l'">
                    <template v-for="(listItem, listIndex) in item.fieldData.split(',')">
                      <li :key="`dt_${listIndex}`" style="list-style: disc" class="ml30">{{ listItem }} : {{ detailMain[item.fieldCdNm][listIndex] }}</li>
                    </template>
                  </span>
                  <!-- 입력 종류: 링크리스트 -->
                  <span v-else-if="item.fieldType == 'CMS003.ll'">
                    <boardLinkButton v-model="detailMain[item.fieldCdNm]" :fieldData="item.fieldData" :tabindex="tabindex+7"/>
                  </span>
                  <!-- 입력 종류: 날짜 -->
                  <span v-else-if="item.fieldType == 'CMS003.d'">
                    {{ doDateFormat(detailMain[item.fieldCdNm]) }}
                  </span>
                  <!-- 입력 종류: 일시 -->
                  <span v-else-if="item.fieldType == 'CMS003.dt'">
                    {{ doDateTimeFormat(detailMain[item.fieldCdNm]) }}
                  </span>
                  <!-- 입력 종류: 기간 -->
                  <span v-else-if="item.fieldType == 'CMS003.p'">
                    {{ doDateFormat(detailMain[item.fieldCdNm][0]) }} ~ {{ doDateFormat(detailMain[item.fieldCdNm][1]) }}
                  </span>
                  <!-- 입력 종류: 선택상자, 라디오 -->
                  <span v-else-if="item.fieldType =='CMS003.s' || item.fieldType  =='CMS003.r'"> 
                    <boardDetailSelect v-model="detailMain[item.fieldCdNm]" :fieldData="item.fieldData"/>
                  </span>
                  <!-- 입력 종류: 주소 -->
                  <span v-else-if="item.fieldType == 'CMS003.a'">
                    <template v-for="item in detailMain[item.fieldCdNm]">
                      {{item}}
                    </template>
                  </span>  
                  <span v-else>{{ detailMain[item.fieldCdNm] }}</span>
                </li>
              </template>
            </ul>
            <!-- 컬럼: 요약글 -->
            <div class="txt_info" v-html=" $sanitize(decodeHTML(detailMain.field22), boardDefaults)"></div>
            <div class="img_list" v-if="imageList != null && imageList.length > 0">
              <!-- 이미지리스트 -->
              <div style="width: 100%;"> 
                <ul class="img_list_ul" ref="imgList">
                  <li v-for="(item2, index2) in imageList" :key="index2" :class="item2.imgNo==imgMainView.imgNo?'on':''" :ref="`imgDtl${index2}`"> 
                    <a>
                      <img :src="encodeURI(item2?.imgUrl)" @click="changeImgMainView(item2)" @keydown="doKeydownImgMainView($event, item2)" :tabindex="tabindex+8">
                    </a>
                  </li>
                </ul>
              </div>
              <div style="width: 100%;" class="img_list_ul_ctrl">
                <p class="prev pre" id="pfrm_img_left">
                  <button class="img_list_button" @click="doPrevNextImg('prev')" @keydown="doKeydownPrevNextImg($event, 'prev')" :disabled="curIdx ==0" :tabindex="curIdx ==0?-1:tabindex+9">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                  </button>
                </p>
                <p class="next" id="pfrm_img_right">
                  <button class="img_list_button" @click="doPrevNextImg('next')" @keydown="doKeydownPrevNextImg($event, 'next')" :disabled="curIdx == (imageList.length-1)" :tabindex="curIdx==(imageList.length-1)?-1:tabindex+10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="board.attcYn == 'Y' && detailMain.attcCnt > 0" class="event_cont" style="padding:10px 10px 10px 10px;">
          <fileDownload :attcNo="detailMain.attcNo" class="exhbitFileDown"/>
        </div>
        <div class="event_cont"> 
          <p v-html="$sanitize(detailMain.field08, boardDefaults)"></p>
          <Kogl v-if="detailMain.koglCd != null && detailMain.koglCd != ''" :koglCd="detailMain.koglCd" />
        </div>
        <!-- 부서정보표시 -->
        <div id="board" style="width:100%;" v-if="(detailMain.staffDept != null && detailMain.staffDept != '') || (detailMain.staffTel != null && detailMain.staffTel != '') || (detailMain.staffNm != null && detailMain.staffNm != '')">
          <div class="table_bview">
            <table cellspacing="0" :summary="`${board.boardNm}${$t('dateWriter')}`">
              <tbody>
                <tr>
                  <td>
                    <li style="float:right">
                      <td class="borderBtNone">
                        <li style="float:right">
                          <dl class="dept_info" v-if="detailMain.staffDept != null && detailMain.staffDept != ''">
                            <dt>{{$t('dept')}}</dt>
                            <dd>{{ detailMain.staffDept }}</dd>
                          </dl>
                          <dl class="dept_info" v-if="detailMain.staffTel != null && detailMain.staffTel != ''">
                            <dt>{{$t('tel')}}</dt>
                            <dd>{{ detailMain.staffTel }}</dd>
                          </dl>
                          <dl class="dept_info" v-if="detailMain.staffNm != null && detailMain.staffNm != ''">
                            <dt>{{$t('manager')}}</dt>
                            <dd>{{ detailMain.staffNm }}</dd>
                          </dl>
                        </li>
                      </td>
                    </li>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <!-- 버튼 -->
      <postButtonList v-model="detailMain" :board="board" :nextNo="nextNo" :prevNo="prevNo" :routeDiv="routeDiv" @doPw="doPw()" :tabindex="tabindex+9"/>
      <!-- 댓글 -->
      <Comment v-if="board.commYn == 'Y'" :delYn="detailMain.delYn" :board="board" :tabindex="tabindex+20"/>
    </div>
  </div>
</template>

<script>
import ChildTab from '@/views/component/ChildTab'
import fileDownload from '@/views/component/fileDownload'
import imageDownload from '@/views/component/ImageDownload'
import Comment from '@/views/component/Comment'
import Kogl from '@/views/component/Kogl'
import boardLinkButton from '@/views/component/BoardLinkButton'
import postButtonList from '@/views/component/PostButtonList'
import boardDetailSelect from "@/views/component/BoardDetailSelect";

import HDate from '@/libs/date';
import { boardDefaults } from '@/libs/sanitize'
import {decodeHTMLEntities} from '@/libs/comm/util'

export default {
  components: {
    ChildTab,
    fileDownload,
    imageDownload,
    Comment,
    Kogl,
    boardLinkButton,
    postButtonList,
    boardDetailSelect,
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    depth: {
      type: Number,
      default: 0
    },
    grpList: {
      type: Array,
      default: () => []
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed:{
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    }
  },
  watch: {
    grpList(newVal){
      if(newVal != null && newVal.length > 0) {
        this.setCurrentTab()
      }
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.searchParam.postNo = newVal.postNo;
          this.searchParam.grpCd = newVal[`s${this.depth}`]
          this.searchParam.vtime = newVal.vtime;

          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab()
          } 
          this.doDetail();
        }
      }
    }
  },
  data() {
    return {
      searchParam: {
        contentNo : '',
        postNo: '',
        attcNo: '',
        inputPw: null,
        grpCd: null,
        modifyYn: null,
        originBoardNo: null,
        boardDepth: 0,
      },
      vtime: '1',
      isPwChk: null,
      fieldList:[],
      detailMain: {
        contentNo: null,
        postNo: null,
        termsNo: 0,
        unLimitedYn: 'N',
        nextCloseYn: 'N' ,
        popupTypeCd: null,
        accCd: null,
        dispStDt:null,
        dispEnDt:null,
        useYn: null,
        nextCloseDd:null,
        widthPixel: null,
        heightPixel: null,
        topPixel:'0',
        leftPixel:'0',
        content:'<p></p>',
        noticeYn:'N',
        mainYn: 'N',
        ndisYn: 'N',
        grpCd: null,
        grpNm: null,
        id: null,
        pw: null,
        field01: null,
        field02: null,
        field03: null,
        field04: null,
        field05: null,
        field06: null,
        field07: null,
        field08: null,
        field09: null,
        field10: null,
        field11: null,
        field12: null,
        field13: null,
        field14: null,
        field15: null,
        field16: null,
        field17: null,
        field18: null,
        field19: null,
        field20: null,
        field21: null,
        field22: null,
        field23: null,
        field24: null,
        postSt: null,
        postStDt: null,
        postEnDt: null,
        postEn: null,
        postStTm: null,
        postEnTm: null,
        mainDispStDt: null,
        mainDispEnDt: null,
        mainDispStTm: null,
        mainDispEnTm: null,
        mainTermLimitYn: 'N',
        noticeDispStDt: null,
        noticeDispEnDt: null,
        noticeDispStTm: null,
        noticeDispEnTm: null,
        noticeTermLimitYn: 'N',
        orginPostNo: null,
        requApprYn: 'N',
        selApprYn: 'N',
        attcNo: null,
        delYn: 'N',
        koglCd: null,
      },
      imageList: [],
      prevNo: null,
      nextNo: null,
      currentTab: 'none',
      subContentType: 'A',
      isInit: true,
      routeDiv: 'dynamic',
      imgMainView: {
        url: '',
        index: '',
      },
      boardDefaults,
      curIdx: 0
    }
  },
  mounted() {
    if(this.$route.name.indexOf('direct') == 0) {
      this.routeDiv = 'direct'
    }

    if(this.grpList != null && this.grpSize > 0) {
      this.setCurrentTab()
    }  

    this.searchParam.contentNo = this.board?.boardNo;
    this.searchParam.postNo = this.$route.query.postNo;
    this.searchParam.grpCd = this.$route.query[`s${this.depth}`];
    this.searchParam.vtime = this.$route.query.vtime;
    
    this.doBoard();
  },
  methods: {
    setCurrentTab() {
      this.currentTab = this.$route.query[`s${this.depth}`]
    },
    isTab(){
      // 그룹 탭 리스트 
      const grpList = this.grpList;
      // 그룹 사용 컴포넌트 코드(탭 / 선택상자)
      const grpDispCd = this.board.grpDispCd;
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 비공개 여부
      const ndisYn = this.board.ndisYn;
      // 비공개 필수 여부
      const ndisRequYn = this.board.ndisRequYn;

      if(grpList == null || this.grpSize < 1){
        return false;
      }

      if(grpYn != 'Y' || grpDispCd != 'CMS009.tab'){
        return false;
      }

      if(ndisYn == 'Y' && ndisRequYn == 'Y'){
        return false;
      }

      return true;
    },
    changeTab(data, contentType, key, isSameAllow=false) { 
      this.searchParam.contentNo = this.board?.boardNo;
      this.searchParam.postNo = this.$route.query.postNo;
      this.searchParam.grpCd = key

      const query = {...this.$route.query}
      query[`s${this.depth}`] = key
      
      if(this.searchParam.contentNo != null && this.searchParam.contentNo != ''){
        if(this.isInit){
          this.isInit = false
        }else{
          if(isSameAllow) {
            const param = {query:{...query}, name:'content'};
              
            delete param.query.postNo;
            delete param.query.page;

            //주소가 같이 않으면 주소 변경
            if(JSON.stringify(this.$route.query) != JSON.stringify(param.query)) {
              this.$router.push(param);
            }
          }
        }
      }
    },
    setMetaInfo() {
      // 현재 메인이미지URL
      const imgMain = this.imgMainView.imgUrl;
      // 메타태그 이미지URL
      let ogImageUrl = null;
      // 분류명
      const grpNm = this.detailMain.grpNm;
      // 현재 URL
      const url = document.location.href;
      // 제목
      const title = this.detailMain.field03;
      // 요약글
      const ctntTemp = this.$sanitize(this.detailMain.field22, {allowedTags:[]}).replace(/(\n|\t|\r)/g, ""); 
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 게시판 이름
      const boardNm = this.board.boardNm;
      
      if(imgMain != null && imgMain != '') {
        ogImageUrl = imgMain;
      }else{
        ogImageUrl = '/logo.png';
      }
      
      let metaInfoData = {
        title: title,
        ogTitle: title,
        ogUrl: url,
        ogDescription: ctntTemp,
        ogImage: ogImageUrl,
      };
      
      if((grpNm != null && grpNm != '') && grpYn == 'Y') {
        metaInfoData.title = `${this.$t('nationalSj')}: ${boardNm} > ${grpNm}-${this.$t('detail')}`
      }else{
        metaInfoData.title = `${this.$t('nationalSj')}: ${boardNm}-${this.$t('detail')}`
      } 

      this.$store.dispatch('setMetaInfo', metaInfoData);
    },
    doBoard() {
      this.$nextTick(() => { 
        if(this.board.ndisYn == 'Y') {
          this.isPwChk = 'N';
          this.$tran('/site/external/contents/selectIsValid', this.searchParam).then(response => {
            if(response.data.isToken){
              this.doDetail();
            }
          })
        }else{
          this.isPwChk = 'Y';
          this.doDetail();
        }
      })
    },
    doDetail() {
      this.$tran('/site/external/contents/selectPostDetail', this.searchParam).then(response => {
        if(response.data == null || response.data == ''){
          return false;
        }
        this.fieldList = response.data.fieldList;
        this.detailMain = response.data.post;
        this.prevNo = response.data.prevNextPost?.prevPostNo;
        this.nextNo = response.data.prevNextPost?.nextPostNo;
        this.imageList = response.data.imageList;
        
        this.isPwChk = 'Y';

        this.$nextTick(() => {
          if(this.imageList != null && this.imageList.length > 0) {
            this.imgMainView = this.imageList[0]
          }

          this.setMetaInfo();
        })
      })
      // .catch(e => {
      //   this.$store.dispatch('setIsNoPage', true);
      //   this.$store.dispatch('setCurMenuNm', null);
      // })
    },
    goBack() {
      let name = 'content'
      const query = {...this.$route.query}
      delete query.postNo
      
      if(this.routeDiv == 'direct') {
        name = 'directBoard'
      }

      this.$router.push({name: name, query: query})
    },
    doChkPw() {
      const inputPw = this.searchParam.inputPw;

      if(inputPw == null || inputPw == '') {
        this.$swal.fire(this.$t('error'), this.$t('message.L000004') , 'error')
        return;
      }
      this.doDetail();
    },
    doPwEnter(event) {
      if(event.keyCode == 13) {
        this.doChkPw();
      }
    },
    changeTimeTab(vtime) {
      const query = {...this.$route.query};
      query.vtime = vtime;
      this.searchParam.vtime = vtime;

      this.$router.push({name: 'content', query: query});
    },
    changeImgMainView(item) {
      this.imgMainView = item;
    }, 
    doPrevNextImg(div) {
      if(div == 'prev') {
        this.curIdx--;
        const prevImg = this.imageList[this.curIdx];

        if(prevImg != null) {
          this.imgMainView = prevImg;
        }
      }else{
        this.curIdx++;
        const nextImg = this.imageList[this.curIdx];

        if(nextImg != null) {
          this.imgMainView = nextImg;
        }
      }
      const imgList = this.$refs.imgList;
      const imgDtl = this.$refs[`imgDtl${this.curIdx}`][0]
      imgList.scrollLeft = imgDtl.offsetLeft
    },
    doDateFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'yyyy-MM-dd');
      }

      return formatDate
    },
    doDateTimeFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'yyyy-MM-dd HH:mm:ss');
      }

      return formatDate
    },
    decodeHTML(str) {
      if(str != null && str != '') {
        return decodeHTMLEntities(str)
      }
      return
    },
    doImageError(e) {
      e.target.src = require('@/assets/img/board/errorImage.jpg')
    },
    doPw() {
      this.searchParam.inputPw=null;
      this.doBoard();
    },
    doKeydownBack(event) {
      if(event.keyCode == 13) {
        this.goBack();
      }
    },
    doKeydownImgMainView(event, item) {
      if(event.keyCode == 13) {
        this.changeImgMainView(item);
      }
    },
    doKeydownPrevNextImg(event, div) {
      if(event.keyCode == 13) {
        this.doPrevNextImg(div);
      }
    }
  }
}
</script>