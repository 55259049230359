
export default class HDate {

  constructor(routes) {
    //요일명칭 정의
    this.weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    //축약 요일명칭 정의
    this.weekShortName = ["일", "월", "화", "수", "목", "금", "토"];
    //월명칭 정의
    this.monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
    //축약 월명칭 정의.
    this.monthShortName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
    //오전/오후
    this.ttName = ["오전", "오후"];

    //요일명칭 정의
    this.weekNameEng = ["SunDay", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    //축약 요일명칭 정의
    this.weekShortNameEng = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    //월명칭 정의
    this.monthNameEng = ["January", "February", "March", "Aprill", "May", "June", "July", "August", "September", "October", "November", "December"];
    //축약 월명칭 정의.
    this.monthShortNameEng = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //오전/오후
    this.ttNameEng = ["AM", "PM"];
  }

  /**
   * 좌측문자열채우기
   * @params
   *  - str : 원 문자열
   *  - padLen : 최대 채우고자 하는 길이
   *  - padStr : 채우고자하는 문자(char)
   */
  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
        console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
        return str;
    }
    str += ""; // 문자로
    padStr += ""; // 문자로
    while (str.length < padLen)
        str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  /**
   * value의 Date 여부 반환.
   * @param {date} value 확인할 value.
   * @return {boolean} Date 여부.
   * @example
   */
  isDate(value) {
    return value instanceof Date;
  }

  /**
   * 입력된 날짜에 OffSet으로 지정된 만큼의 날짜를 증감한다.
   * @param {string|date} value 'yyyyMMdd'형태로 표현된 String 또는 Date.
   * @param {number} offset 일단위 증가(또는 감소값).
   * @return {string|date} 'yyyyMMdd'형태로 표현된 String 또는 Date Value.
   */
  addDate(value, offset, format='yyyyMMdd') {
    let dt;
    if(typeof(offset) === 'string'){
      offset = parseInt(offset)
    }
    if ( this.isDate(value) ) {
      dt = new Date();
      dt.setFullYear(value.getFullYear(), value.getMonth(), value.getDate() + offset);
      dt.setHours(value.getHours(), value.getMinutes(), value.getSeconds());
      dt.setMilliseconds(value.getMilliseconds());
      return dt;
    } else {
      dt = new Date();
      dt.setFullYear(parseInt(value.substr(0, 4)), parseInt(value.substr(4, 2)-1), parseInt(value.substr(6, 2)) + offset);
      let hour = 0;
      if(value.length > 8){
        hour = parseInt(value.substr(8, 2))
      }
      let minutes = 0;
      if(value.length > 10){
        minutes = parseInt(value.substr(10, 2))
      }
      let second = 0;
      if(value.length > 12){
        second = parseInt(value.substr(12, 2))
      }
      dt.setHours(hour, minutes, second);
      dt.setMilliseconds(0);
      return this.getMaskFormatString(dt, format);
    }
  }

  /**
   * mask format 변환 정보 반환.
   * @private
   * @param {string} strMask mask할 format 문자열.
   * @return {array} mask format 변환 정보.
   */		
  _parseDateMask(strMask) {   
    const arrMask = []
    let tokenStr = '';
    let seq = 0;
    let bEscape = false;
    let bQuote = false;
    let maskArr = strMask.split("");
    let tmpStr;

    for ( let i = 0, len = maskArr.length ; i < len ; ) {
      tokenStr = maskArr[i];
      if( bEscape == false && tokenStr == "'" )  { // Mask가 Quotation이 시작될 경우.
        if( bQuote == false ) { bQuote = true;}
        else {bQuote = false;}
        i++;
        continue;
      }

      if( bEscape == false && tokenStr == "\\" && !bQuote )  { // Mask에서 Escape에 진입할 경우.
        bEscape = true;
        i++;
        continue;
      } else if ( bEscape ) { // Mask에서 Escape를 사용할 경우.
        arrMask[seq] = i;
        seq++;
        bEscape = false;
      } else if( bQuote == false )  {// Mask에서 Quotation 밖의 글자에 대해.
        tmpStr = strMask.substring(i, i+4);
        if ( tmpStr == "yyyy" || tmpStr == "MMMM" || tmpStr == "dddd" ) {
          arrMask[seq] = tmpStr;
          i += 4;
          seq++;
          continue;
        }
        
        tmpStr = strMask.substring(i, i+3);
        if ( tmpStr == "MMM" || tmpStr == "ddd" || tmpStr == "sss" ) {
          arrMask[seq] = tmpStr;
          i += 3;
          seq++;
          continue;
        }
        tmpStr = strMask.substring(i, i+2);
        if ( tmpStr == "yy" || tmpStr == "MM" || tmpStr == "dd" ||
          tmpStr == "HH" || tmpStr == "mm" || tmpStr == "ss" ||
          tmpStr == "hh" || tmpStr == "tt" || tmpStr == "tt" ) // yy, MM, dd, HH, mm , ss, tt, WW
        {
          arrMask[seq] = tmpStr;
          i += 2;
          seq++;
          continue;
        }

        if ( tokenStr == "M" || tokenStr == "d" || tokenStr == "H" ||
          tokenStr == "h" || tokenStr == "m" || tokenStr == "s" || tokenStr == "W" ) // M, d, H, h, m, s, W
        {
          arrMask[seq] = tokenStr;
          seq++;
        } else {
          arrMask[seq] = i;
          seq++;
        }
      }
      i++;
    }
    
    return arrMask;
  }

  getWeekNumber(dt) {
    dt.setHours(0, 0, 0);
    dt.setDate(dt.getDate() + 4 - (dt.getDay() || 7));
    return Math.ceil((((dt - new Date(dt.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
  }

  /**
       * yyyyMMdd 형태의 날짜를 입력하면 해당연도의 날짜에 해당하는 주차반환.
       * @param {date|string} date Date Object 또는 날짜형 문자열.
       * @return {number} 주차에 해당하는 숫자.
       * @example
       * let dt = this.strToDate("20130331"); // convert Date type from "20130331".
       * let week = this.getWeekOfYear(dt);
       * trace(week); // output : 14
       * let dtstr = "20130331";
       * let week = this.getWeekOfYear(dt);
       * trace(week); // output : 14
       */
  getWeekOfYear(date){
    if ( typeof(date) === string ) {
      date = this.strToDate(date);
    }
    if ( !this.isDate(date) ) {
      return -1;
    }
    
    const onejan = new Date();
    
    onejan.setYear(date.getFullYear());
    onejan.setMonth(0);
    onejan.setDate(1);	
                
    return Math.ceil((((date - onejan) / 86400000) + onejan.getDay()+1)/7);
  }

  /**
   * yyyy, yyyyMM, yyyyMMdd, yyyyMMddhh, yyyyMMddhhmm, yyyyMMddhhmmss 형태의 문자열을 Date객체로 반환.
   * @param {string} value 날짜 문자열.
   * @return {date} Date Object.
   * @example
   * const dt = this.strToDate("20120331"); // convert Date type from "20120331".
   * trace(dt); // output : Sat Mar 31 2012 00:00:00 GMT+0900
   * const dt = this.strToDate("20130320123022"); // convert Date type from "20130320123022".
   * trace(dt); // output : Wed Mar 20 2013 12:30:22 GMT+0900
   */
  strToDate(value) {
    const dt = new Date();
    if ( value.length == 4 ) { //yyyy 
      dt.setFullYear(parseInt(value), 0, 0);
      dt.setHours(0, 0, 0);
      dt.setMilliseconds(0);
    } else if ( value.length == 6 ) { //yyyyMM
      dt.setFullYear(parseInt(value.substring(0,4)), parseInt(value.substring(4,6))-1, 0);
      dt.setHours(0, 0, 0);
      dt.setMilliseconds(0);
    } else if ( value.length == 8 ) { //yyyyMMdd
      dt.setFullYear(parseInt(value.substring(0,4)), parseInt(value.substring(4,6))-1, parseInt(value.substring(6,8)));
      dt.setHours(0, 0, 0);
      dt.setMilliseconds(0);
    } else if ( value.length == 10 ) { //yyyyMMddhh
      dt.setFullYear(parseInt(value.substring(0,4)), parseInt(value.substring(4,6))-1, parseInt(value.substring(6,8)));
      dt.setHours(parseInt(value.substring(8,10)), 0, 0);
      dt.setMilliseconds(0);
    } else if ( value.length == 12 ) {//yyyyMMddhhmm 
      dt.setFullYear(parseInt(value.substring(0,4)), parseInt(value.substring(4,6))-1, parseInt(value.substring(6,8)));
      dt.setHours(parseInt(value.substring(8,10)), parseInt(value.substring(10,12)), 0);
      dt.setMilliseconds(0);
    } else if ( value.length == 14 ) { //yyyyMMddhhmmss 
      dt.setFullYear(parseInt(value.substring(0,4)), parseInt(value.substring(4,6))-1, parseInt(value.substring(6,8)));
      dt.setHours(parseInt(value.substring(8,10)), parseInt(value.substring(10,12)), parseInt(value.substring(12,14)));
      dt.setMilliseconds(0);
    }
    return dt;
  }

  /**
   * 주어진 날짜 객체의 Mask Format 처리된 문자열을 반환.<br>
   * 요일명칭, 월 명칭, 오전/오후 명칭 표시 처리는 Eco.date에 정의된 값으로 처리된다.<br><br>
   * this.weekName : 요일명칭(Array value), <br>
   * this.weekShortName : 요일축약명칭(Array value),<br>
   * this.monthName : 월명칭(Array value),<br>
   * this.monthShortName : 월축약 명칭(Array value),<br>
   * this.ttName : 오전/오후 명칭(Array value)
   * @param {date} dt Date 개체.
   * @param {string} strMask mask할 format 문자열.
   * @return {string} 변환된 문자열.
   * @example
   * let dt =date.strToDate("20130430123412"); // convert Date type from "20130430123412".
   * trace(this.getMaskFormatString(dt, "yyyy년 MM월 dd일 tt hh시 mm분 ss초")); // output : 2013년 04월 30일 오후 12시 34분 12초
   * trace(this.getMaskFormatString(dt, "yyyy-MM-dd")); // output : 2013-04-30
   * trace(this.getMaskFormatString(dt, "yy MM.dd")); // output : 13 04.30
   * trace(this.getMaskFormatString(dt, "yyyy-MM-dd W \\Week")); // output : 2013-04-30 18 Week
   * trace(this.getMaskFormatString(dt, "MMMM dddd")); // output : 4월 화요일
   */
  getMaskFormatString(dts, strMask, locale='ko') {    
    const arrMask = this._parseDateMask(strMask)
    const arrDt = []
    let mask = ''
    let h = '';
    for ( let i = 0, len = arrMask.length; i < len ; i++ ) {
      mask = arrMask[i];  
      if ( mask > -1 ) {
        arrDt[arrDt.length] = strMask.charAt(mask);
      } else {
        if(!(dts instanceof Array)){
          dts = [dts]
        }
        for(let c = 0, d=dts; dts.length > c; c++){
          const dt = dts[c];
          switch (mask) {
            case "yyyy": arrDt[arrDt.length] = new String(dt.getFullYear()); break;
            case "MMMM": arrDt[arrDt.length] = locale=='eng'?this.monthNameEng[dt.getMonth()]:this.monthName[dt.getMonth()]; break;
            case "dddd": arrDt[arrDt.length] = locale=='eng'?this.weekNameEng[dt.getDay()]:this.weekName[dt.getDay()]; break;
            case "MMM": arrDt[arrDt.length] = locale=='eng'?this.monthShortNameEng[dt.getMonth()]:this.monthShortName[dt.getMonth()]; break;
            case "ddd": arrDt[arrDt.length] = locale=='eng'?this.weekShortNameEng[dt.getDay()]:this.weekShortName[dt.getDay()]; break;
            case "sss": arrDt[arrDt.length] = new String(this.lpad(dt.getMilliseconds(),3,'0')); break;
            case "yy": arrDt[arrDt.length] = new String(this.lpad(dt.getFullYear() % 1000,2,'0')); break;
            case "MM": arrDt[arrDt.length] = new String(this.lpad(dt.getMonth() + 1,2,'0')); break;
            case "WW": arrDt[arrDt.length] = new String(this.lpad(this.getWeekNumber(dt),2,'0')); break;
            case "dd": arrDt[arrDt.length] = new String(this.lpad(dt.getDate(),2,'0')); break;
            case "HH": arrDt[arrDt.length] = new String(this.lpad(dt.getHours(),2,'0')); break;
            case "hh": arrDt[arrDt.length] = new String(this.lpad(((h = dt.getHours() % 12) ? h : 12),2,'0')); break;
            case "mm": arrDt[arrDt.length] = new String(this.lpad(dt.getMinutes(),2,'0')); break;
            case "ss": arrDt[arrDt.length] = new String(this.lpad(dt.getSeconds(),2,'0')); break;
            case "tt": arrDt[arrDt.length] = dt.getHours() < 12 ? this.ttName[0] : this.ttName[1]; break;
            case "M": arrDt[arrDt.length] = new String(dt.getMonth() + 1); break;
            case "d": arrDt[arrDt.length] = new String(dt.getDate()); break;
            case "H": arrDt[arrDt.length] = new String(dt.getHours()); break;
            case "h": arrDt[arrDt.length] = new String(((h = dt.getHours() % 12) ? h : 12)); break;
            case "m": arrDt[arrDt.length] = new String(dt.getMinutes()); break;
            case "s": arrDt[arrDt.length] = new String(dt.getSeconds()); break;
            case "W": arrDt[arrDt.length] = new String(this.getWeekOfYear(dt)); break;
          }
        }
      }
    }
    return arrDt.join("");
  }
}
