<template>
  <div class="board_button">
    <div class="fl pt10">
      <a v-if="nextNo != null && nextNo != ''" @click="doPrevNext(nextNo)" @keydown="doKeydownPrevNext($event, nextNo)" :tabindex="tabindex">
        <img src="@/assets/img/board/nninc_simple/img/listup_bt.gif" :alt="$t('nextPost')">
      </a>
      <a v-if="prevNo != null && prevNo != ''" @click="doPrevNext(prevNo)" @keydown="doKeydownPrevNext($event, prevNo)" :tabindex="tabindex+1">
        <img src="@/assets/img/board/nninc_simple/img/listdown_bt.gif" :alt="$t('prevPost')">
      </a>
    </div>
    <div class="fr pt10">
      <template v-if="board.writeYn == 'Y'">
        <span>
          <a @click="doWrite()" @keydown="doKeydownWrite($event)" class="cbtn cbtn_point" :tabindex="tabindex+2">{{$t('write')}}</a>
        </span>
        <template v-if="value.delYn != 'Y'">
          <span>
            <a @click="doModify()" @keydown="doKeydownModify($event)" class="cbtn cbtn_g ml5" :tabindex="tabindex+3">{{$t('update')}}</a>
          </span>
          <span>
            <a @click="doBoard()" @keydown="doKeydownBoard($event)" class="cbtn cbtn_g ml5" :tabindex="tabindex+4">{{$t('delete')}}</a>
          </span>
        </template>
      </template>
      <span v-if="board.replyYn == 'Y' && value.noticeYn != 'Y' && value.delYn != 'Y'">
        <a  @click="doReply()" @keydown="doKeydownReply($event)" class="cbtn cbtn_point ml5" :tabindex="tabindex+5">{{$t('reply')}}</a>
      </span>
      <span>
        <a @click="doList()" @keydown="doKeydownList($event)" class="cbtn cbtn_g ml5" :tabindex="tabindex+6">{{$t('homepageList')}}</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    nextNo: {

    },
    prevNo: {

    },
    board: {
      type: Object
    },
    routeDiv: {

    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      searchParam: {
        contentNo: null,
        postNo: null,
        attcNo: null,
        originBoardNo: null,
        boardDepth: null,
      },
    }
  },
  methods: {
    doPrevNext(postNo) {
      if(postNo != null && postNo != '') {

        this.searchParam.postNo = postNo;
        const qeury = {...this.$route.query}
        qeury.postNo = postNo.toString();

        this.$router.push({query: qeury});
      }
    },
    doWrite() {
      const query = {...this.$route.query};
      delete query.postNo;
    
      this.$router.push({name: `${this.routeDiv}Modify`, query: query});
    },
    doModify() {
      this.$router.push({name:`${this.routeDiv}Modify`, query: this.$route.query});
    },
    doBoard() {
      if(this.board.writeYn == 'Y') {
        this.setSearchParam();
        this.$tran('/site/external/contents/selectIsValidDel', this.searchParam).then(response => {
          if(response.data.isToken){
            this.doDelete();
          }else{
            this.doPwDel(); 
          }
        })
      }
    },
    doPwDel() {
      this.$swal.fire({
        title: '비밀번호 입력',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#08a17b',
        preConfirm: (password) => {
          this.searchParam.inputPw = password;
          this.setSearchParam();

          return this.$tran('/site/external/contents/deletePostChk', this.searchParam).then(response => {
            if(!response.data.isToken) {
              throw new Error();
            }

            return response.data.isToken
          })
          .catch(error => {
            this.$swal.showValidationMessage('비밀번호가 일치하지 않습니다.')
          })
        },
        allowOutsideClick: () => this.$swal.isLoading()
      })
      .then((result) => {
        if(result.isConfirmed) {
          this.doDelete();
        }
      })
    },
    doDelete() {
      this.setSearchParam();
      this.$tran('/site/external/contents/deletePost', this.searchParam).then(response => {
        this.$nextTick(() => {
          this.doList()
        })
      })
    },
    setSearchParam() {
      this.searchParam.contentNo = this.value.boardNo;
      this.searchParam.postNo = this.value.postNo;
      this.searchParam.attcNo = this.value.attcNo;
      this.searchParam.originBoardNo = this.value.originBoardNo;
      this.searchParam.boardDepth = this.value.boardDepth;
    },
    doList() {
      let name = 'content'
      const query = {...this.$route.query}

      delete query.postNo
      delete query.orginPostNo
      
      if(this.routeDiv == 'direct') {
        name = 'directBoard'
      }
      
      this.$router.push({name: name, query: query})
    },
    doReply() {
      const query = {...this.$route.query};
      query.orginPostNo = query.postNo;
      delete query.postNo;
      
      this.$router.push({name:`${this.routeDiv}Modify`, query: query})
    },
    doKeydownPrevNext(event, postNo) {
      if(event.keyCode == 13) {
        this.doPrevNext(postNo);
      }
    },
    doKeydownWrite(event) {
      if(event.keyCode == 13) {
        this.doWrite();
      }
    },
    doKeydownModify(event) {
      if(event.keyCode == 13) {
        this.doModify();
      }
    },
    doKeydownBoard(event) {
      if(event.keyCode == 13) {
        this.doBoard();
      }
    },
    doKeydownReply(event) {
      if(event.keyCode == 13) {
        this.doReply();
      }
    },
    doKeydownList(event) {
      if(event.keyCode == 13) {
        this.doList();
      }
    },
  }
}
</script>