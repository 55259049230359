<template>
  <editor ref="tuieditor" :initialValue="value" :options="defaultOptions" initialEditType='wysiwyg' @change="doChange"></editor>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';
import { sendPostImg } from '@/libs/comm/transection';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';

export default {
  props:{
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  components: {
    editor: Editor
  },
  watch: {
    value(newVal) {
      if(newVal != null && newVal != '') {
        this.$refs.tuieditor.editor.setHTML(newVal)
      }
    }
  },
  data() {
    return {
      defaultOptions : {
        minHeight: '200px',
        useCommandShortcut: true,
        usageStatistics: true,
        hideModeSwitch: false,
        placeholder:this.placeholder,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'link'],
          ['code', 'codeblock'],
          ['scrollSync'],
        ],
        plugins: [colorSyntax, tableMergedCell]
      }
    }
  },
    mounted() {
        this.$refs.tuieditor.invoke("addHook", "addImageBlobHook", function (
            file,
            callback
        ) {

        const extList = ["jpg", "jpeg", "png", "bmp", "svg", "gif"];
        const fileSize = 1024 * 1024 * 5;
        const checkExt = extList.find(element => file.type.indexOf(element) > -1);

        if (checkExt == null) {
            return new swal({
                title: this.$t('imgFile'),
                text: this.$t('imgKind'),
                icon: "warning"
            });
        }

        if (file != null && file.size > fileSize) {
            return new swal({
                title: this.$t('imgFile'),
                text: this.$t('imgSize'),
                icon: "warning"
            });
        }

        file.mainYn = 'N';
        file.ord = 0;

        const insertList = [];
        insertList.push({
            name: file.name,
            mainYn: file.mainYn,
            ord: file.ord
        });

        const formData = new FormData();
        formData.append('files', file);
        formData.append('insertImgList', JSON.stringify(insertList));

        sendPostImg("/site/file/uploadImg", {}, formData).then(response => {
            const imageList = response.data.imageList;
            callback(imageList[0]);
        })

    });
  }, 
  methods: {
    doChange(e) {
      this.$emit('input',this.$refs.tuieditor.editor.getHTML())
    }
  }
}
</script>

<style>

</style>