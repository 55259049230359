<template>
  <div id="cont_wrap">
    <!-- 게시판 컨텐츠 -->
    <!-- 주요역할 : 일반형/이미지형/달력형/이미지리스트/전시형/FAQ형/예약형 상세 분기 -- 게시판 설정 조회 및 전달 -->
    <component v-if="board.boardNo != null && !isLoading" :is="isComponent" :board="board" :grpList="grpList" :depth="depth" :tabindex="tabindex"></component> 
  </div>
</template>

<script>
import dynamicBoard from '@/views/board/dynamicBoard'
import dynamicImage from '@/views/board/dynamicImage'
import dynamicDetail from '@/views/board/dynamicDetail'
import dynamicModify from '@/views/board/dynamicModify'
import dynamicExhbit from '@/views/board/dynamicExhbit'
import dynamicFaq from '@/views/board/dynamicFaq'
import detailExhbit from '@/views/board/detailExhbit'
import dynamicCalendar from '@/views/board/dynamicCalendar'
import dynamicListHrz from '@/views/board/dynamicListHrz'
import dynamicListVrt from '@/views/board/dynamicListVrt'
import detailListVrt from '@/views/board/detailListVrt'

export default {
  components: {
    dynamicBoard, // 일반형
    dynamicImage, // 이미지형
    dynamicDetail,  // 상세
    dynamicModify,  // 수정
    dynamicExhbit,  // 전시형
    dynamicFaq, // FAQ형
    detailExhbit, // 전시형 상세
    dynamicCalendar,  // 달력형
    dynamicListHrz, // 이미지리스트(가로)
    dynamicListVrt, // 이미지리스트(세로)
    detailListVrt, // 이미지리스트(세로) 상세
  },
  props: {
    //게시판 번호
    contentNo: {
      type: String,
      default: ''
    },
    // 현재 깊이 s?와 연관됨
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    //게시판 번호 변경시 동작
    contentNo(newVal) {
      this.doList(newVal)
    }
  },
  computed: {
    menuId() {
      // 게시판 설정 조회시 사용
      return this.$route.query.menuId
    },
    isComponent(){
      const routeNm = this.$route.name;
      const boardTypeCd = this.board.boardTypeCd;

      // 게시판의 경우 수정 / 상세 / 리스트로 분기
      if(routeNm == 'dynamicDetail') {
        return 'dynamicDetail'; // 상세(일반형, 이미지형, 달력형)
      }else if(routeNm == 'dynamicModify'){
        return 'dynamicModify'; // 수정
      }else if(routeNm =='dynamicDetailExhbit') {
        return 'detailExhbit';  // 전시형 상세
      }else if(routeNm == 'dynamicDetailListVrt') {
        return 'detailListVrt';  // 이미지리스트(세로) 상세
      }else {
        if(boardTypeCd == 'CMS002.img') {
          return 'dynamicImage';   // 이미지형
        }else if(boardTypeCd == 'CMS002.exh') {
          return 'dynamicExhbit'  // 전시형
        }else if(boardTypeCd =='CMS002.faq') {
          return 'dynamicFaq' // FAQ형
        }else if(boardTypeCd == 'CMS002.nor') {
          return 'dynamicBoard';  // 일반형
        }else if(boardTypeCd == 'CMS002.cal') {
          return 'dynamicCalendar'  // 달력형
        }else if(boardTypeCd == 'CMS002.lis') {
          return 'dynamicListHrz'  // 이미지리스트(가로)
        }else if(boardTypeCd == 'CMS002.lisv') {
          return 'dynamicListVrt' // 이미지리스트(세로)
        }
      }
    }
  },
  data() {
    return {
      searchParam: {
        menuId: '',
        contentNo: '',
      },
      board: {
        boardNo: null,
        boardTypeNm: null,
        accNm: null,
        accCd: null,
        boardNm: null,
        boardTypeCd: null,
        grpDispCd: null,
        writeYn:'Y',
        certYn:'N',
        pwYn:'Y',
        grpYn:'N',
        attcYn:'N',
        attcLimitNo:0,
        attcLimitSize:0,
        attcAllowExt: '',
        attcAllowExts: null,
        replyYn:'N',
        commYn:'N',
        ndisYn:'N',
        ndisRequYn: 'N',
        contentYn:'Y',
        editerYn:'N',
        useTeamYn:'N',
        useStDt:'',
        useEnDt:'',
        useTeamOverYn:'N',
        useYn:'Y',
        delFlagYn:'N',
        limitWord:'',
        postTeamYn:'N',
        postDd:0,
        pagePerNo:0,
        newIconDispDd:0,
        noticeYn:'N',
        mainDispYn:'N',
        defContent:'',
        imgWidth: '',
        imgHeight: '',
        imgRowPerCnt: 0,
      },
      //현재 게시판(this.board.boardNo)에 그룹 
      grpList: [],
      isLoading: false,
    }
  },
  created() {
    //게시판 컴포넌트 처음 진입시 동작(watch와는 중복 동작하지 않음)
    this.doList(this.contentNo)
  },
  methods: {
    // 게시판 설정 및 그룹정보 조화
    doList(contentNo) {
      this.isLoading=true
      //게시판 번호 널처리
      if(contentNo != null && contentNo != '') {
        //게시판 설정조회 세팅
        this.searchParam.contentNo = contentNo

        //게사판 설정 조회
        if(this.menuId != null && this.menuId != ''){
          this.searchParam.menuId = this.menuId
        }

        this.$tran('/site/external/contents/selectBoardDetail', this.searchParam).then(response => {
          this.$set(this, 'board', response.data.board)
          this.$set(this, 'grpList', response.data.grpList)
        }).catch(e => {
          this.$store.dispatch('setIsNoPage', true);
          this.$store.dispatch('setCurMenuNm', null);
        }).finally(() => {
          this.isLoading=false
        })
      }
    },
    doComponent(){
      const routeNm = this.$route.name;
      const boardTypeCd = this.board.boardTypeCd;

      // 게시판의 경우 수정 / 상세 / 리스트로 분기
      if(routeNm == 'dynamicDetail') {
        return 'dynamicDetail'; // 상세(일반형, 이미지형, 달력형)
      }else if(routeNm == 'dynamicModify'){
        return 'dynamicModify'; // 수정
      }else if(routeNm =='dynamicDetailExhbit') {
        return 'detailExhbit';  // 전시형 상세
      }else if(routeNm == 'dynamicDetailListVrt') {
        return 'detailListVrt';  // 이미지리스트(세로) 상세
      }else {
        if(boardTypeCd == 'CMS002.img') {
          return 'dynamicImage';   // 이미지형
        }else if(boardTypeCd == 'CMS002.exh') {
          return 'dynamicExhbit'  // 전시형
        }else if(boardTypeCd =='CMS002.faq') {
          return 'dynamicFaq' // FAQ형
        }else if(boardTypeCd == 'CMS002.nor') {
          return 'dynamicBoard';  // 일반형
        }else if(boardTypeCd == 'CMS002.cal') {
          return 'dynamicCalendar'  // 달력형
        }else if(boardTypeCd == 'CMS002.lis') {
          return 'dynamicListHrz'  // 이미지리스트(가로)
        }else if(boardTypeCd == 'CMS002.lisv') {
          return 'dynamicListVrt' // 이미지리스트(세로)
        }
      }
    }
  }
}
</script>
