<template>
  <div>
    <div style="margin-top:7px;"></div>
    <div style="margin-bottom:7px;"></div>
    <div id="NFU_file_control">
      <input id="NFU_add_file_tmp" type="file" ref="formFile" style="display: none;" @change="onFileChange" :multiple="fileInfoInner.multiple" :accept="acceptFileExt">
      <!-- <div id="NFU_div_img">
        <img id="NFU_image" src="@/assets/img/common/no.gif" alt="이미지 미리보기">
      </div> -->
      <div id="NFU_div_file_list" @dragenter="onDragenter" @dragover="onDragover" @dragleave="onDragleave" @drop="onDrop" @dblclick="onClickList">
        <table id="NFU_file_list">
          <tbody>
            <tr id="NFU_drag_msg" v-if="completList == null || completList.length < 1">
              <td style="border: none; text-align: center;">
                <span>{{this.$t('message.F000008')}}</span>
              </td>
            </tr>
            <tr :id="'file_list_'+index" v-for="(val, index) in completList" :key="`B-${index}`" > <!-- class="file_selected" -->
              <td>
                <span class="fileClick" v-if="!fileInfoInner.fileComplet" @click="isDirectDel?fileDirectRemove($event, val, index):fileRemove($event, val, index)" @keydown="doKeydownRemove($event, val, index)" :tabindex="tabindex">
                	<svg width="13px" height="13px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.72 5.72a.75.75 0 011.06 0L12 10.94l5.22-5.22a.75.75 0 111.06 1.06L13.06 12l5.22 5.22a.75.75 0 11-1.06 1.06L12 13.06l-5.22 5.22a.75.75 0 01-1.06-1.06L10.94 12 5.72 6.78a.75.75 0 010-1.06z"/></svg>
                </span>
              </td>
              <td :style="val.delYn=='Y'?{'text-decoration':'line-through'}:{}">{{val.attcFileNm}}.{{val.attcFileExt}}</td>
              <td>{{byteToKBorMB(val.attcFileSize)}}</td>
              <td v-if="val.attcNo != null && val.attcNo != ''">
                <span class="fileClick" @click="fileDownload(val.attcNo, val.seqNo)">
                	<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13px" height="13px" viewBox="0 0 485 485" style="enable-background:new 0 0 485 485;" xml:space="preserve">
                    <path d="M426.5,458h-368C51,458,45,464,45,471.5S51,485,58.5,485h368c7.5,0,13.5-6,13.5-13.5S434,458,426.5,458z"/>
                    <path d="M233,378.7c2.5,2.5,6,4,9.5,4s7-1.4,9.5-4l107.5-107.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0L256,336.5v-323C256,6,250,0,242.5,0S229,6,229,13.5v323l-84.4-84.4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L233,378.7z"/>
                  </svg>
                </span>
              </td>
              <td v-else><span class="fileLoader" v-if="spinner"></span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="div_multi_btn" style="padding-top: 5px; display: block;">
      <input type="button" :value="$t('searchFile')" class="NFU_cbtn NFU_cbtn_point" @click="onClick" :tabindex="tabindex">
      <input type="button" :value="$t('deleteAll')" class="NFU_cbtn NFU_cbtn_g ml5" @click="fileRemoveAll" :tabindex="tabindex">
      <input type="button" :value="$t('downloadAll')" class="NFU_cbtn NFU_cbtn_g ml5" @click="fileDownloadAll" :tabindex="tabindex">
    </div>
    <div>
		  <span style="font-size:12px;color:#DF0101;">{{$t('allowFiles')}} :{{fileInfo.fileCnt}}{{$t('ea')}} <br>{{$t('attcLimitVol')}} : {{byteToKBorMB(fileInfo.fileSize)}} <br>{{$t('allowFileIs')}} {{showFileExt(fileInfo.fileExt)}}</span>
	  </div>
  </div>
</template>

<script>
import { guid, downloadResponse } from '@/libs/comm/util';

export default {
  mounted() {
    this.fileInfoInner = this.fileInfo;
    this.acceptFileExt = this.fileInfoInner.fileExt?.map(ext => '.'+ext);
    this.getCompletList(this.attcNo);
  },
  props: {
    attcNo: {
      type: String
    },
    fileInfo:{
      type: Object
    },
    isDirectDel: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    fileInfo: {
      handler(value) {
        if(value == null){
          this.fileInfoInner = {
            fileComplet: false,
            fileSize: '5242880',
            fileExt: ['hwp', 'doc', 'ppt', 'xls', 'txt', 'gif', 'jpg', 'docx', 'pptx', 'xlsx', 'zip', 'alz', 'rar', 'pdf'],
            fileCnt: 2,
            multiple: false,
            fileDownloadNm: this.$t('attcFile'),
          }
        } else {
          this.fileInfoInner = value
        }
      },
      deep: true
    },
    attcNo(newVal) {
      if(newVal != null && newVal != '') {
        this.getCompletList(newVal)
      }
    }
  },
  data() {
    return {
      fileList: [],
      completList: [],
      isDragged: false,
      deleteList: [],
      fileInfoInner : {
        fileComplet: false,
        fileSize: '5242880',
        fileExt: ['hwp', 'doc', 'ppt', 'xls', 'txt', 'gif', 'jpg', 'docx', 'pptx', 'xlsx', 'zip', 'alz', 'rar', 'pdf'],
        fileCnt: 2,
        multiple: false,
        fileDownloadNm: this.$t('attcFile'),
      },
      spinner: false,
      acceptFileExt: [],
    }
  },
  methods: {
    fileRemoveAll(){
      if(this.fileList.length > 0){
        this.fileList = [];
      }
      for(let i = this.completList.length-1 ; i >= 0 ; i--){
        const item = this.completList[i]
        const attcNo = item.attcNo
        if(attcNo == null || attcNo == ''){
          this.completList.splice(i,1);
        } else {
          item.delYn = 'Y'
          this.$set(this.completList, i, item)
          this.deleteList.push(item)
        }
      }
      this.$refs.formFile.value = null;
    },
    fileRemove(event, val, index){
      event.preventDefault()
      if(val.attcNo == null || val.attcNo == ''){
        this.completList.splice(index,1);
        const fileIdx = this.fileList.findIndex(e => e.fileIdx == val.fileIdx)
        if(fileIdx >= 0){
          this.fileList.splice(fileIdx,1);
        }
      } else {
        val.delYn = 'Y'
        this.$set(this.completList, index, val)
        this.deleteList.push(val)
      }
      this.$refs.formFile.value = null;
    },
    fileDirectRemoveAll() {
      this.fileRemoveAll()
      this.doFile()
    },
    fileDirectRemove(event, val, index) {
      this.fileRemove(event, val, index)
      this.doFile()
    },
    doFile() {
      const formData = this.getFile();
      this.spinner = true;
      this.$tranFile('/site/file/uploadFile', {attcNo: this.attcNo}, formData).then(response => {
        this.fileInfoInner.fileComplet = false;
        const attcNo = response.data.attcNo;
        const fileCnt = this.completList.length??0;
        const delCnt = JSON.parse(formData.get("deleteList")).length??0;

        this.spinner = false;
        this.getCompletList(this.attcNo);
        this.$emit('success', attcNo, fileCnt - delCnt);
      }).catch(error => {
        this.response = error.message
      })
    },
    onClick(){
      this.$refs.formFile.click();
    },
    onClickList(){
      if(this.completList == null || this.completList.length < 1){
        this.$refs.formFile.click();
      }
    },
    onDragenter (event) {
      // class 넣기
      this.isDragged = true
    },
    onDragleave (event) {
      // class 삭제
      this.isDragged = false
    },
    onDragover (event) {
      // 드롭을 허용하도록 prevetDefault() 호출
      event.preventDefault()
    },
    onDrop (event) {
      // 기본 액션을 막음 (링크 열기같은 것들)
      event.preventDefault()
      this.isDragged = false
      const files = event.dataTransfer.files
      if(this.checkFileExt(files)){
        this.$refs.formFile.value = null;
        return;
      }
      this.addFiles(files)
    },
    onFileChange (event) {
      const files = event.target.files;
      if(this.checkFileExt(files)){
        this.$refs.formFile.value = null;
        return;
      }
      this.addFiles(files)
    },
    getFile() {
      const formData = new FormData();
      for (let i = 0; i < this.fileList.length; i++) {
        formData.append('fileList', this.fileList[i]);
      }
      formData.append('deleteList', JSON.stringify(this.deleteList));
      this.fileList = [];
      this.deleteList = [];
      return formData
    },
    async addFiles (files) {
      const maxCnt = this.fileInfoInner.fileCnt
      let delCnt = 0;
      if(this.deleteList != null){
        delCnt = this.deleteList.length;
      }

      if((files.length -delCnt > maxCnt) || (files.length + this.fileList.length - delCnt > maxCnt) || (files.length + this.completList.length- delCnt > maxCnt)){
        this.$swal.fire(this.$t('warning'), this.$t('message.F000005',{cnt: maxCnt}), 'warning');
        this.$refs.formFile.value = null;
        return;
      }

      let size = 0;
      const filesize = Number(this.fileInfoInner.fileSize);
      const pushList = [...this.completList];
      for(let i = 0; i < files.length; i++) {
        const file = files[i];
        size = Number(size) + Number(file.size);
        if(filesize < size){
          this.$swal.fire(this.$t('warning'), this.$t('message.F000004',{maxSize: this.byteToKB(filesize) + 'kb', size: this.byteToKB(size) + 'kb'}), 'warning');
          return;
        }
        
        const key = guid()
        file.fileIdx = key
        this.fileList.push(file);
        const fileRow = {
          attcFileExt: this.getExt(file.name),
          attcFileNm: this.getFileNm(file.name),
          attcFileSize: file.size,
          attcNo: '',
          attcPath: '',
          attcRealFileNm: '',
          delYn: '',
          seqNo: '',
          userId: '',
          fileIdx: key
        }
        pushList.push(fileRow);
      }
      this.completList = [...pushList];
      this.$emit('addFileComplet', this.completList)
    },
    getCompletList(attcNo){
      if(attcNo != null && attcNo != ''){
        let paramData = {
          attcNo: attcNo
        };
        this.$tran('/site/file/attchFileList', paramData, '').then(response => {
          this.completList = response.data.file;
        }).catch(error => {
            this.response = error.message
        })
      }
    },
    getExt(fileNm){
      return fileNm.substr(fileNm.lastIndexOf('.')+1);
    },
    getFileNm(fileNm){
      return fileNm.substr(0, fileNm.lastIndexOf('.'));
    },
    fileDownload(attcNo, seqNo){
      const paramData = {
          attcNo: attcNo,
          seqNo: seqNo
        };
      this.$tranFileDown('/site/file/downloadFile', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    fileDownloadAll(){
      const firstList = this.completList.find(element => element != undefined);
      const paramData = {
          attcNo: firstList.attcNo,
          fileDownloadNm: this.fileInfoInner.fileDownloadNm
        };
      this.$tranFileDown('/site/file/downloadFile', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    byteToKB(byteVal){
      return Math.trunc(Number(byteVal) / 1024);
    },
    byteToKBorMB(byteVal){
      const KB = Math.trunc(Number(byteVal) / 1024);
      const MB = Math.trunc(Number(KB) / 1024)
      if (KB > 1024){
        return MB+' MB';
      } else if (KB == 0){
        return byteVal+' KB';
      } else {
        return KB+' KB';
      }
    },
    checkFileExt(files, message=true){
      for(let i = 0; i < files.length; i++){
        const ext = this.getExt(files[i].name).toLowerCase();
        const fileInfo = this.fileInfoInner.fileExt;
        const accept = fileInfo?.some(element => {
          return element.toLowerCase() == ext;
        })
        if(!accept) {
          if(message){
            this.$swal.fire(this.$t('warning'), this.$t('message.F000003',{ext: ext, allowExts: fileInfo?.join(', ')}), 'warning');
          }
          return true;
        }
      }
      return false;
    },
    showFileExt(fileExt){
      if(fileExt != null && fileExt.length > 0) {
        return fileExt.join(',').replace().replaceAll('.','')
      }

      return 
    },
    doKeydownRemove(event, val, index) {
      if(event.keyCode == 13) {
        if(this.isDirectDel) {
          this.fileDirectRemove(event, val, index);
        }else{
          this.fileRemove(event, val, index);
        }
      }
    }
  }
}
</script>