<template>
  <div id="board_wrap">
    <!-- 본인실명확인서비스 요청 form --------------------------->
    <template v-if="(board.writeYn == 'Y' && board.certYn == 'Y' && (detailMain.id == null || detailMain.id == ''))" >
      <div class="cash_box">
        <p>
          <a @click="doCashDeletePopup()">{{$t('noCert')}}</a>
        </p>
      </div>
      <div class="memberbox ipin">
        <div class="box">
          <p class="tit icon12">
            <strong>{{$t('phoneCert')}}</strong>
          </p>
          <div class="con">
            {{$t('acessCert')}}<br>{{$t('acessCert1')}}
          </div>
          <p class="btn">
            <a @click="doIpinWindow()" @keydown="doKeydownIpinWindow($event)" class="con_btn blue" :title="$t('newWin')" :tabindex="tabindex+1">{{$t('phoneCert3')}}</a>
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="isPwChk=='N'">
        <div id="board" style="width:100%; margin-top: 20px;">
          <div class="pwd_check">
            {{$t('message.L000004')}}<br><br>
            <ul>
              <li>
                <label for="c_pwd" class="text">{{$t('pw')}} :</label> 
                <input type="password" size="15" :title="$t('pwInput')" v-model="searchParam.inputPw" @keydown="doPwEnter" :tabindex="tabindex+1" class="board_input" style="vertical-align:middle;" autocomplete="off">
                <img src="@/assets/img/board/nninc_simple/img/command_ok.gif" width="34" height="20" :alt="$t('confirm')" style="vertical-align:middle;padding-left:3px;" @click="doChkPw" @keydown="doPwEnter" :tabindex="tabindex+1"/>
              </li>
            </ul>
          </div>
          <div class="pwd_check_button">
            <span>
              <a @click="goBack" @keydown="doKeydownBack($event)" class="cbtn cbtn_g" :tabindex="tabindex+2">{{$t('back')}}</a>
            </span>
          </div>
        </div>
      </template>
      <template v-if="isPwChk=='Y'">
        <!-- 약관동의 -->
        <Terms v-if="(board.certYn == 'Y' && !isModify)" v-model="detailMain.termsNo" @changeAgreeYn="changeAgreeYn" :tabindex="tabindex+2"/>
        <!-- 쓰기-->
        <div id="board" :style="routeDiv=='direct'?'padding-top: 30px;':''">
          <div class="guide">
            <span>
              <img src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif" :alt="$t('requList')">{{$t('message.requCheck')}}
            </span>
          </div>
          <div class="table_bwrite">
            <boardTable :summary="`${$t(title)}`+`${$t('of')}`+ `${summary}`+`${$t('ckIt')}`" :fields="fieldList" :isModify="true" :board="board">
              <template #colgroup></template>
              <template #body>
                <!-- 그룹사용여부 -->
                <tr v-if="board.grpYn=='Y'">
                  <th scope="row" colspan="2">
                    {{$t('categori')}}
                  </th>
                  <td>
                    <select v-model="detailMain.grpCd" class="modifySelect" :tabindex="tabindex+100">
                      <template v-for="(item, index) in boardGrpCdList">
                        <option v-if="doSelect(item,index,'grpCd')" selected :value="item.grpCd" :key="`sel_${index}`">{{item.grpNm}}</option>
                        <option v-else :value="item.grpCd" :key="`nosel_${index}`">{{item.grpNm}}</option>
                      </template>
                    </select>
                  </td>
                </tr>
                <!-- 비공개여부 -->
                <tr v-if="board.ndisYn == 'Y' && board.ndisRequYn == 'N'">
                  <th scope="row" colspan="2">
                    <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{$t('openYn')}}
                  </th>
                  <td>
                    <input type="radio" v-model="detailMain.ndisYn" value="N" :tabindex="tabindex+101">
                      <label for="N">{{$t('open')}}</label>
                    <input type="radio" v-model="detailMain.ndisYn" value="Y" class="ml10" :tabindex="tabindex+101">
                      <label for="Y">{{$t('ndisYn')}}</label>
                  </td>
                </tr>
                <!-- 공지여부 -->
                <template v-if="board.noticeYn == 'Y'">
                  <!-- 공지사용 유무 -->
                  <tr>
                    <th scope="row" colspan="2">
                      <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{$t('homepageNoticeYn')}}
                    </th>
                    <td>
                      <input type="radio" v-model="detailMain.noticeYn" value="Y" :tabindex="tabindex+102">
                        <label for="Y" class="ml2">{{$t('use')}}</label>
                      <input type="radio" v-model="detailMain.noticeYn" value="N" class="ml10" :tabindex="tabindex+102">
                        <label for="N" class="ml2">{{$t('noUse')}}</label>
                    </td>
                  </tr>
                  <!-- 공지 기간 및 무제한 사용 유무 -->
                  <tr v-if="detailMain.noticeYn == 'Y'">
                    <th scope="row" colspan="2">
                      <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{$t('noticeTerm')}}
                    </th>
                    <td>
                      <input type="date" v-model="detailMain.noticeDispStDt" :tabindex="tabindex+103"><input type="time" style="margin-left: 4px;" v-model="detailMain.noticeDispStTm" :tabindex="tabindex+104"> ~ 
                      <input type="date" v-model="detailMain.noticeDispEnDt" :tabindex="tabindex+105"><input type="time" style="margin-left: 4px;" v-model="detailMain.noticeDispEnTm" :tabindex="tabindex+106">
                      <input type="checkbox" v-model="detailMain.noticeTermLimitYn" true-value="Y" false-value="N" class="ml10" :tabindex="tabindex+107">
                      <label :for="detailMain.noticeTermLimitYn" class="pl3">{{$t('noticeTermNoLimit')}}</label>
                    </td>
                  </tr>
                </template>
                <!-- 메인표시여부 -->
                <template v-if="board.mainDispYn == 'Y'">
                  <!-- 메인표시 사용 유무 -->
                  <tr>
                    <th scope="row" colspan="2">
                      <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{$t('homepageMainDispYn')}}
                    </th>
                    <td>
                      <input type="radio" v-model="detailMain.mainYn" value="Y" :tabindex="tabindex+108">
                        <label for="Y">{{$t('use')}}</label>
                      <input type="radio" v-model="detailMain.mainYn" value="N" class="ml10" :tabindex="tabindex+108">
                        <label for="N">{{$t('noUse')}}</label>
                    </td>
                  </tr>
                  <!-- 메인표시 기간 및 무제한 사용여부 -->
                  <tr v-if="detailMain.mainYn == 'Y'">
                    <th scope="row" colspan="2">
                      <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{$t('mainDispTerm')}}
                    </th>
                    <td>
                      <input type="date" v-model="detailMain.mainDispStDt" :tabindex="tabindex+109"><input type="time" style="margin-left: 4px;" v-model="detailMain.mainDispStTm" :tabindex="tabindex+110"> ~ 
                      <input type="date" v-model="detailMain.mainDispEnDt" :tabindex="tabindex+111"><input type="time" style="margin-left: 4px;" v-model="detailMain.mainDispEnTm" :tabindex="tabindex+112">
                      <input type="checkbox" v-model="detailMain.mainTermLimitYn" true-value="Y" false-value="N" class="ml10" :tabindex="tabindex+113">
                      <label :for="detailMain.mainTermLimitYn" class="pl3">{{$t('mainTermNoLimit')}}</label>
                    </td>
                  </tr>
                </template>
                <!-- 게시기간(달력형 게시판일때는 무조건 입력) -->
                <tr v-if="board.postTeamYn=='Y' || board.boardTypeCd == 'CMS002.cal'">
                  <th scope="row" colspan="2">
                    <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif">{{$t('postDd')}}
                  </th>
                  <td>
                    <input type="date" v-model="detailMain.postStDt" format="yyyymmdd" :tabindex="tabindex+114"><input type="time" style="margin-left: 4px;" v-model="detailMain.postStTm" :tabindex="tabindex+115"> ~ 
                    <input type="date" v-model="detailMain.postEnDt" format="yyyymmdd" :tabindex="tabindex+116"><input type="time" style="margin-left: 4px;" v-model="detailMain.postEnTm" :tabindex="tabindex+117">
                  </td>
                </tr>
                <!-- 비밀번호사용여부 -->
                <tr v-if="!isModify">
                  <th scope="row" colspan="2">
                    <label>
                      <img :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{$t('pw')}}
                    </label>
                  </th>
                  <td>
                    <input type="password" size="22" class="board_input" v-model="detailMain.pw" maxlength="256" :tabindex="tabindex+118"> 
                    <span class="text1">* {{$t('message.inputPw')}}</span>
                  </td>
                </tr>
                <!-- 공공누리 -->
                <tr v-if="board.koglYn=='Y'">
                  <th scope="row" colspan="2">
                    <label>{{$t('kogl')}}</label>
                  </th>
                  <td>
                    <select v-model="detailMain.koglCd" :tabindex="tabindex+119">
                      <template v-for="(item, index) in koglCdList">
                        <option v-if="doSelect(item, index, 'koglCd')" selected :value="item.cmnCd" :key="`sel_${index}`">{{$t(item.mulLangCd)}}</option>
                        <option v-else :value="item.cmnCd" :key="`nosel_${index}`">{{$t(item.mulLangCd)}}</option>
                      </template>
                    </select>
                  </td>
                </tr>
                <template v-for="(item, index) in fieldList">
                  <!-- 입력 유형이 리스트인건 따로 구현 -->
                  <tr :key="`tr_${index}`" v-if="item.fieldType != 'CMS003.l'">
                    <th v-if="item.fieldType !='CMS003.e'" scope="row" style="word-break: break-all;overflow-wrap: break-word;" colspan="2" :rowspan="setRowspan(item)">
                      <label :for="item.fieldCdNm">
                        <img v-if="item.requYn =='Y'" :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{item.fieldNm}}
                      </label>
                    </th>
                    <td :colspan="item.fieldType =='CMS003.e'?3:''" :class="item.fieldType =='CMS003.e'?'padding0':''">
                      <!-- 입력 -->
                      <input v-if="item.fieldType=='CMS003.i'" type="text" :size="item.fieldCdNm =='field03'?100:20" class="board_input postInput" v-model="detailMain[item.fieldCdNm]" :maxlength="item.fieldCdNm =='field03'?200:item.fieldCdNm =='field02'?50:1000" :readonly="item.fieldCdNm =='field02' && board.certYn == 'Y'?true:false" v-tooltip.top-start="item.tooltip" :tabindex="tabindex+120"> 
                      <!-- 라디오 -->
                      <span v-tooltip.top-start="item.tooltip" v-if="item.fieldType=='CMS003.r'">
                        <template v-for="(subItem, subIdx) in getOption(item, item.fieldType=='CMS003.r',detailMain)" >
                          <input type="radio" :value="subItem.value" v-model="detailMain[item.fieldCdNm]" :key="`radio_${subIdx}`" class="ml5" :tabindex="tabindex+120">
                          <label :for="subItem.value" :key="`label_${subIdx}`" class="ml2 mr5">{{subItem.value}}</label> 
                        </template>
                      </span>
                      <!-- 체크박스 -->
                      <span v-tooltip.top-start="item.tooltip" v-if="item.fieldType=='CMS003.c'">  
                        <template v-for="(subItem, subIdx) in getOption(item, item.fieldType=='CMS003.c', detailMain)">
                          <input type="checkbox" :value="subItem.value" v-model="detailMain[item.fieldCdNm]" :key="`radio_${subIdx}`" class="ml5" :tabindex="tabindex+120">
                          <label :for="subItem.value" :key="`label_${subIdx}`" class="ml2 mr5">{{subItem.value}}</label>
                        </template>
                      </span>
                      <!-- 콤보 -->
                      <span v-tooltip.top-start="item.tooltip" v-if="item.fieldType=='CMS003.s'">
                        <select v-model="detailMain[item.fieldCdNm]" :tabindex="tabindex+120">
                          <option v-for="(subItem, subIdx) in getOption(item, item.fieldType=='CMS003.s', detailMain)" :value="subItem.value" :key="`select_${subIdx}`" >{{subItem.text}}</option>
                        </select>
                      </span>
                      <!-- 일 -->
                      <input v-if="item.fieldType=='CMS003.d'" type="date" v-model="detailMain[item.fieldCdNm]" v-tooltip.top-start="item.tooltip" :tabindex="tabindex+120">
                      <!-- 일시 -->
                      <input v-if="item.fieldType=='CMS003.dt'" type="datetime-local" v-model="detailMain[item.fieldCdNm]" v-tooltip.top-start="item.tooltip" :tabindex="tabindex+120">
                      <!-- 태그 -->
                      <textarea v-if="item.fieldType=='CMS003.t'" v-model="detailMain[item.fieldCdNm]" style="width:100%; height:250px;" maxlength="4000" v-tooltip.top-start="item.tooltip" :tabindex="tabindex+120"></textarea>
                      <!-- 장문입력 -->
                      <textarea v-if="item.fieldType=='CMS003.ta'" v-model="detailMain[item.fieldCdNm]" class="postInput" style="height:250px;" maxlength="4000" v-tooltip.top-start="item.tooltip" :tabindex="tabindex+120"></textarea>
                      <!-- 에디터 -->
                      <tui-editor ref="toastuiEditor" v-if="item.fieldType=='CMS003.e'" v-model="detailMain[item.fieldCdNm]" class="editorTd" v-tooltip.top-start="item.tooltip" :tabindex="tabindex+120"/>
                      <!-- 링크리스트 -->
                      <span v-tooltip.top-start="item.tooltip" v-if="item.fieldType =='CMS003.ll'">
                        <boardLinkInput v-model="detailMain" :fieldData="item" style="display: flex; align-items: center;" :tabindex="tabindex+120"/>
                      </span>
                      <!-- 기간 -->
                      <span v-tooltip.top-start="item.tooltip" v-if="item.fieldType =='CMS003.p'">
                        <!-- 초기값 설정 때문에 따로 컴포넌트로 분리 -->
                        <boardPeriodInput v-model="detailMain" :fieldData="item" :tabindex="tabindex+120"/>
                      </span>
                      <!-- 주소 -->
                      <span v-tooltip.top-start="item.tooltip" v-if="item.fieldType =='CMS003.a'">
                        <boardAddrInput v-model="detailMain" :fieldData="item" :tabindex="tabindex+120"/>
                      </span>
                    </td>
                  </tr>
                  <!-- 리스트 -->
                  <template v-else>
                    <tr v-for="(listItem, listIndex) in item.fieldData.split(',')" :key="listIndex">
                      <th :rowspan="item.fieldData.split(',').length" v-if="listIndex==0">
                        <label :for="item.fieldCdNm">
                          <img v-if="item.requYn =='Y'" :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{item.fieldNm}}
                        </label>
                      </th>
                      <th scope="row" class="thlist">
                        <label for="b_temp9">
                          <img v-if="item.requYn =='Y'" :alt="$t('requList')" src="@/assets/img/board/nninc_simple/img/ic_vcheck.gif"> {{ listItem }}
                        </label>
                      </th>
                      <td>
                        <span v-tooltip.top-start="item.tooltip">
                          <!-- 초기값 설정 때문에 따로 컴포넌트로 분리 -->
                          <boardListInput v-if="item.fieldType =='CMS003.l'" v-model="detailMain" :fieldData="item" :listIndex="listIndex" :tabindex="tabindex+120"/>
                        </span>
                      </td>
                    </tr>
                  </template>
                </template>
                <!-- 첨부파일 -->
                <tr v-if="board.attcYn == 'Y'">
                  <th scope="row" colspan="2">
                    <label>{{$t('attcFile')}}</label>
                  </th>
                  <td>
                    <fileUpload ref="fileUploadComp" :attcNo="detailMain.attcNo" :fileInfo="fileInfo" :tabindex="tabindex+121"/>
                  </td>
                </tr>
                <!-- 이미지 첨부파일 -->
                <tr v-if="board.boardTypeCd == 'CMS002.img' || board.boardTypeCd == 'CMS002.lis' || board.boardTypeCd == 'CMS002.lisv' || board.boardTypeCd == 'CMS002.exh' || board.boardTypeCd == 'CMS002.exhn'">
                  <th scope="row" colspan="2">
                    <label>{{$t('img')}}</label>
                  </th>
                  <td>
                    <imageUpload ref="imageUploadComp" :boardNo="detailMain.boardNo" :postNo="detailMain.postNo" :fileInfo="imageInfo" :tabindex="tabindex+122"/>
                  </td>
                </tr>
              </template>
            </boardTable>
          </div>

          <!-- 버튼 -->
          <div class="board_button2">
            <span>
              <input class="cbtn cbtn_point" :value="$t('save')" @click="doSave" @keydown="doKeydownSave($event)" :tabindex="tabindex+123">
            </span>
            <span>
              <a @click="doList" class="cbtn cbtn_g ml5" @keydown="doKeydownList($event)" :tabindex="tabindex+124"> {{$t('cancel')}}</a>
            </span>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import boardTable from '@/views/board/boardTable'
import Terms from '@/views/component/Terms'
import tuiEditor from '@/views/component/TuiEditor';
import fileUpload from '@/views/component/fileUpload'
import imageUpload from '@/views/component/ImageUpload'
import boardListInput from '@/views/component/BoardListInput'
import boardLinkInput from '@/views/component/BoardLinkInput'
import boardPeriodInput from '@/views/component/BoardPeriodInput'
import boardAddrInput from '@/views/component/BoardAddrInput'

import { mapGetters } from 'vuex'
import {sendPostImg} from '@/libs/comm/transection';
import { nullCheck } from '@/libs/comm/util';
import {isJson} from "@/libs/comm/util";

import HDate from '@/libs/date';

export default {
  components: {
    Terms,
    boardTable,
    fileUpload,
    imageUpload,
    'tui-editor': tuiEditor,
    boardListInput,
    boardLinkInput,
    boardPeriodInput,
    boardAddrInput,
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    depth: {
      type: Number,
      default: 0
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  computed: {
    ...mapGetters([
      'certData',
    ]),
  },
  watch: {
    certData(newVal) {
      if(newVal != null && newVal != ''){
        const param = JSON.parse(newVal)
        this.doResult(param)
      }
    },
    saveData(newVal) {
      if(newVal != null && newVal != '') {
        this.saveData = newVal
      }
    }
  },
  data() {
    return {
      condCode: [
        {cd: 'CMS015', useYn: 'Y', listNm: 'koglCdList'},
      ],
      koglCdList: [],
      reqNum: '',
      saveData: {
        id: '',
        nm: '',
        requApprYn: 'N',
        selApprYn: 'N',
      },
      searchParam: {
        writeYn: 'Y',
        contentNo : '',
        postNo: '',
        inputPw: null,
        orginPostNo: '',
        modifyYn: 'Y',
      },
      isPwChk: null,
      fieldList: [],
      boardGrpCdList: [],
      commList: [],
      detailMain: {
        contentNo: null,
        postNo: null,
        termsNo: 0,
        unLimitedYn: 'N',
        nextCloseYn: 'N' ,
        popupTypeCd: null,
        accCd: null,
        dispStDt:null,
        dispEnDt:null,
        useYn: null,
        nextCloseDd:null,
        widthPixel: null,
        heightPixel: null,
        topPixel:'0',
        leftPixel:'0',
        content:'<p></p>',
        noticeYn:'N',
        mainYn: 'N',
        ndisYn: 'N',
        grpCd: null,
        id: null,
        pw: null,
        field01: null,
        field02: null,
        field03: null,
        field04: null,
        field05: null,
        field06: null,
        field07: null,
        field08: null,
        field09: null,
        field10: null,
        field11: null,
        field12: null,
        field13: null,
        field14: null,
        field15: null,
        field16: null,
        field17: null,
        field18: null,
        field19: null,
        field20: null,
        field21: null,
        field22: null,
        field23: null,
        field24: null,
        postStDt: null,
        postEnDt: null,
        postStTm: null,
        postEnTm: null,
        mainDispStDt: null,
        mainDispEnDt: null,
        mainDispStTm: null,
        mainDispEnTm: null,
        mainTermLimitYn: 'N',
        noticeDispStDt: null,
        noticeDispEnDt: null,
        noticeDispStTm: null,
        noticeDispEnTm: null,
        noticeTermLimitYn: 'N',
        orginPostNo: null,
        requApprYn: 'N',
        selApprYn: 'N',
        attcNo: null,
        koglCd: '',
      },
      fileInfo: {
        fileComplet: null,
        fileSize: '5242880', 
        fileExt: ['hwp', 'doc', 'ppt', 'xls', 'txt', 'gif', 'jpg', 'docx', 'pptx', 'xlsx', 'zip', 'alz', 'rar', 'pdf'],
        fileCnt: 2,
        multiple: false,
        fileDownloadNm: this.$t('attcFile'),
      },
      imageInfo: {
        fileComplet: false,
        fileSize: '5242880',
        fileExt: ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif'],
        fileCnt: 2,
        multiple: false,
        fileDownloadNm: this.$t('attcFile'),
      },
      saveParam: {
        data: {}
      },
      isModify: false,
      summary: '',
      title: '',
      ctntFieldType: '',
      routeDiv: 'dynamic',
      certId: null,
    }
  },
  mounted() {
    const defalutCode = {
      cdNm: this.$t('select'),
      cmnCd: null,
      dtlCd: null,
      mulLangCd: 'select',
    }

    this.$codeLists(this, this.condCode).then(() => {
      this.koglCdList = [defalutCode,...this.koglCdList];
    })

    if(this.$route.name.indexOf('direct') == 0) {
      this.routeDiv = 'direct';
    }

    this.searchParam.contentNo = this.board?.boardNo;
    this.detailMain.contentNo = this.board?.boardNo;
    this.searchParam.postNo = this.$route.query.postNo;
    this.searchParam.orginPostNo = this.$route.query.orginPostNo;
    this.title = this.board.boardNm;

    if(this.searchParam.orginPostNo != null && this.searchParam.orginPostNo != ''){
      this.searchParam.postNo = this.$route.query.orginPostNo;
    }

    if(this.searchParam.postNo != null && this.searchParam.postNo != '') {
      this.isModify=true;

      if(this.board.pwYn == 'Y') {
        this.isPwChk='N';
      }
      
      if(this.board.certYn != 'Y') {
        this.doDetail();
      }
    }else{
      this.doBoardInfo();
    }
  },
  methods: {
    goBack() {
      let name = 'content'
      const query = {...this.$route.query}
      delete query.postNo
      
      if(this.routeDiv == 'direct') {
        name = 'directBoard'
      }

      this.$router.push({name: name, query: query})
    },
    doChkPw() {
      const inputPw = this.searchParam.inputPw;

      if(inputPw == null || inputPw == '') {
        this.$swal.fire(this.$t('error'), this.$t('message.L000004') , 'error')
        return;
      }
      this.doDetail();
    },
    doPwEnter(event) {
      if(event.keyCode == 13) {
        this.doChkPw();
      }
    },
    doBoardInfo(){
      let summaryData = ''
      this.$tran('/site/external/contents/selectBoardInfo', this.searchParam).then(response => {
        this.fieldList = response.data.fieldList;
        this.boardGrpCdList = response.data.grpList;
        
        this.isPwChk='Y';

        this.$nextTick(() => {
          const ctntData = this.fieldList.find((e) => e.fieldCdNm == 'field08');
          
          if(ctntData != null) {
            this.ctntFieldType = ctntData.fieldType;
          }

          const metaInfoData = {
            title: `${this.$t('nationalSj')}: ${this.board.boardNm}-${this.$t('write')}`
          }

          this.$store.dispatch('setMetaInfo', metaInfoData);

          if(ctntData != null) { 
            if(this.ctntFieldType == 'CMS003.e') {
              this.detailMain.field08 = this.board.defContent;
            }
          }

          if(this.board.postTeamYn=='Y') {
            const hdate = new HDate();
            const today = hdate.getMaskFormatString(new Date(),'yyyy-MM-dd');
            const postStTm = hdate.getMaskFormatString(new Date(),'H:mm');
            let postStDt = hdate.getMaskFormatString(new Date(),'yyyyMMdd');
            const postEnDt = hdate.addDate(postStDt, this.board.postDd,'yyyy-MM-dd');

            this.detailMain.postStDt = today;
            this.detailMain.postStTm = postStTm;
            this.detailMain.postEnDt = postEnDt;
            this.detailMain.postEnTm = postStTm;
          }

          if(this.board.pwYn == 'Y' || this.board.ndisYn == 'Y'	) {
            summaryData += ', ' + this.$t('pw')
          }
          this.summary = summaryData

          if(this.board.attcYn == 'Y') {
            this.fileInfo = {
              fileComplet: null,
              fileSize: this.board.attcLimitSize * 1048576, 
              fileExt: this.board.attcAllowExts,
              fileCnt: this.board.attcLimitNo,
              multiple: true,
              fileDownloadNm: this.$t('attcFile'),
            }
          }

        })
      })
    },
    doDetail() {
      let summaryData = ''
      this.$tran('/site/external/contents/selectPostModDetail', this.searchParam).then(response => {
        if(response.data == null || response.data == ''){
          if(this.board.certYn == 'Y') {
            this.detailMain.id=this.certId;
          }
          return false;
        }

        this.fieldList = response.data.fieldList;
        this.boardGrpCdList = response.data.grpList;
        const postNo = this.searchParam.postNo;
        
        this.isPwChk = 'Y';

        this.$nextTick(() => {
          const ctntData = this.fieldList.find((e) => e.fieldCdNm == 'field08');
          
          if(ctntData != null) {
            this.ctntFieldType = ctntData.fieldType;
          }

          const metaInfoData = {
            title: `${this.$t('nationalSj')}: ${this.board.boardNm}-${this.$t('write')}`
          }
          this.$store.dispatch('setMetaInfo', metaInfoData);

          if(postNo!= null && postNo != '') {
            this.isModify = true;
            this.detailMain = response.data.post;

            if(this.board.certYn == 'Y') {
              this.detailMain.id=this.certId;
            }
            if(this.searchParam.orginPostNo != null && this.searchParam.orginPostNo != '') {
              this.isModify = false;
              this.detailMain.orginPostNo=this.searchParam.orginPostNo
              this.detailMain.acctCnt=0
              this.detailMain.attcNo=''
              this.detailMain.boardDepth=this.detailMain.boardDepth+1;
              this.detailMain.crud='N';
              this.detailMain.field03=`RE: ${this.detailMain.field03}`;
              this.detailMain.field08=`<p></p><p>-------------------------------------------------- ${this.$t('orginPostCtnt')} -----------------------------------------------------------</p>${this.detailMain.field08}`;
              this.detailMain.field17='';
              this.detailMain.ip=null;
              this.detailMain.field01=null;
              this.detailMain.field04=null;
              this.detailMain.field05=null;
              this.detailMain.field06=null;
              this.detailMain.field07=null;
              this.detailMain.field09=null;
              this.detailMain.field10=null;
              this.detailMain.field11=null;
              this.detailMain.field12=null;
              this.detailMain.field13=null;
              this.detailMain.field14=null;
              this.detailMain.field15=null;
              this.detailMain.field16=null;
              this.detailMain.field18=null;
              this.detailMain.field19=null;
              this.detailMain.field20=null;
              this.detailMain.field21=null;
              this.detailMain.field22=null;
              this.detailMain.field23=null;
              
              if(this.board.certYn != 'Y') {
                this.detailMain.field02=null;
              }
            }
          }else{
            if(ctntData != null) { 
              if(this.ctntFieldType == 'CMS003.e') {
                this.detailMain.field08 = this.board.defContent;
              }
            }
          }

          for(let i=0; i < this.fieldList.length; i++){
            if(summaryData == null || summaryData == '') {
              summaryData += this.fieldList[i].fieldNm
            }else{
              summaryData += ', ' + this.fieldList[i].fieldNm
            }
          }
          if(this.board.pwYn == 'Y') {
            summaryData += ', ' + this.$t('pw')
          }
          this.summary = summaryData

          if(this.board.attcYn == 'Y') {
            this.fileInfo = {
              fileComplet: null,
              fileSize: this.board.attcLimitSize * 1048576, 
              fileExt: this.board.attcAllowExts,
              fileCnt: this.board.attcLimitNo,
              multiple: true,
              fileDownloadNm: this.$t('attcFile'),
            }
          }
        })
      })
    },
    changeAgreeYn(name, data) {
      this.detailMain[name] = data
    },
    doSelect(item, index, cdNm) {
      const depthGrpCd =  this.$route.query[`s${this.depth}`]

      if(cdNm == 'grpCd') {
        if(this.detailMain.grpCd == null || this.detailMain.grpCd == '') {
          if(depthGrpCd != null && depthGrpCd != '') {
            this.detailMain.grpCd = depthGrpCd
          }else{
            if(index == 0){
              this.detailMain.grpCd=item.grpCd
              return true
            }
          }
        }
        return false
      }else if(cdNm == 'koglCd') {
        if(this.detailMain.koglCd == null || this.detailMain.koglCd == '') {
          if(index == 0) {
            this.detailMain.koglCd = item.cmnCd;
            return true
          }
        }
      }
    },
    passCheckBoard(pwd) {
      const regPass = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,15}$/;

      if(!regPass.test(pwd)) {
        this.$swal.fire(this.$t('error'), this.$t('usePw'), 'error');
        return false;
      }
      
      // 영문, 숫자, 특수문자 혼용
      let chk = 0; 
      let eng = false;
      let num = false;
      let emo = false;
      if(pwd.search(/[0-9]/g) != -1 ){
        chk ++;
        num = true;
      }
      if(pwd.search(/[a-z]/ig)  != -1 ){
        chk ++;
        eng = true;
      }
      if(pwd.search(/[!@#$%^&*()?_~]/g)  != -1  ){
        chk ++;
        emo = true;
      }
      if(chk < 3) {
        this.$swal.fire(this.$t('error'), this.$t('usePw'), 'error');
        return false;
      }

      if(!/^[a-zA-Z0-9!@#$%^&*()?_~]{8,15}$/.test(pwd)) {
        this.$swal.fire(this.$t('error'), this.$t('usePw'), 'error');
        return false;
      }
      
      // 동일한 문자/숫자 4이상, 연속된 문자
      if(/(\w)\1\1\1/.test(pwd) || this.isContinuedValue(pwd)) {
        this.$swal.fire(this.$t('error'), this.$t('numPw'), 'error');
        return false;
      }
      return true;
    },
    isContinuedValue(value) {
      let intCnt1 = 0;
      let intCnt2 = 0;
      let temp0 = "";
      let temp1 = "";
      let temp2 = "";
      let temp3 = "";

      for (var i = 0; i < value.length-3; i++) {
        temp0 = value.charAt(i);
        temp1 = value.charAt(i + 1);
        temp2 = value.charAt(i + 2);
        temp3 = value.charAt(i + 3);

        if (temp0.charCodeAt(0) - temp1.charCodeAt(0) == 1
            && temp1.charCodeAt(0) - temp2.charCodeAt(0) == 1
            && temp2.charCodeAt(0) - temp3.charCodeAt(0) == 1) {
          intCnt1 = intCnt1 + 1;
        }

        if (temp0.charCodeAt(0) - temp1.charCodeAt(0) == -1
            && temp1.charCodeAt(0) - temp2.charCodeAt(0) == -1
            && temp2.charCodeAt(0) - temp3.charCodeAt(0) == -1) {
          intCnt2 = intCnt2 + 1;
        }
      }
      return (intCnt1 > 0 || intCnt2 > 0);
    },
    doSave() {
      let url = ''
      this.saveParam.data={...this.detailMain, crud:'N'};
      this.saveParam.data.contentNo = this.searchParam.contentNo
      
      if(!this.isModify) {
        if(nullCheck(this.detailMain.pw, this.$t('pw'))) {
          return;
        }

        if(!this.passCheckBoard(this.detailMain.pw)) {
          return;
        }
      }

      if(this.board.postTeamYn=='Y' || this.board.boardTypeCd == 'CMS002.cal') {
        if(nullCheck(this.detailMain.postStDt, this.$t('mainDispStDt'))) {
          return;
        }

        if(nullCheck(this.detailMain.postEnDt, this.$t('mainDispEnDt'))) {
          return;
        }
      }

      // 필수 값 체크
      for(let i=0; i < this.fieldList.length; i++){
        let item = this.fieldList[i]
        if(item.requYn == 'Y') {
          if(nullCheck(this.detailMain[item.fieldCdNm], item.fieldNm)) {
            return;
          }
        }
      }

      if(this.isModify) {
        this.saveParam.data.crud='U'
        url = '/site/external/contents/updatePost';
      }else{
        if(this.board.certYn == 'Y' && this.detailMain.termsNo != null && this.detailMain.termsNo != '') {
          if(this.detailMain.requApprYn != 'Y' && !this.isModify) {
            this.$swal.fire(this.$t('error'), this.$t('privacyChk'), 'error');
            return
          }
        }

        url = '/site/external/contents/insertPost';
      } 
      
      let fileList = new FormData();
      let fList = new FormData();

      const boardTypeCd = this.board.boardTypeCd;

      if(boardTypeCd == 'CMS002.img' || boardTypeCd == 'CMS002.lis' || boardTypeCd == 'CMS002.lisv' || boardTypeCd == 'CMS002.exh' || boardTypeCd == 'CMS002.exhn') {
        fileList = this.$refs.imageUploadComp.getFile();
      }
      
      if(this.board.attcYn == "Y"){
        fList = this.$refs.fileUploadComp.getFile();
      }   

      for (let data of fList.entries()) {
          fileList.append(data[0], data[1]);
      }

      sendPostImg(url, this.saveParam, fileList).then(response => {
        this.$nextTick(() => {
          this.doList()
        })
      }).catch(e=> {
        if(this.board.pwYn == 'Y') {
          this.searchParam.inputPw=null;
          this.isPwChk='N';
        }
      })
    },
    doList() {
      let name = 'content'
      const query = {...this.$route.query}
      delete query.boardNo
      delete query.postNo
      delete query.orginPostNo;
      
      if(this.board.ndisYn == 'Y') {
        // 비공개 사용할 경우 현재 뎁스의 그룹정보 삭제
        delete query[`s${this.depth}`]
      }

      if(this.$route.name.indexOf('direct') == 0) {
        name = 'directBoard'
      }
      
      this.$router.push({name: name, query: query});
    },
    getOption(item, isExec=false, obj){
      const result = [];
      if(isJson(item.fieldData)){
        const fieldData = item.fieldData;
        const result = JSON.parse(fieldData)

        result.forEach((e)=>{
          if(!e.hasOwnProperty('value')){
            e.value = e.text
          }
        })
        return result
      }else{
        if(isExec){
          if(item.fieldType=='CMS003.c' && this.detailMain[item.fieldCdNm] == null){
            this.detailMain[item.fieldCdNm]=[]
          }
          const vsOptions = item.fieldData
          
          if(vsOptions != null && vsOptions != ''){
            const vaOptions = vsOptions.split(',')
            const result = []
            for(const val in vaOptions){
              let data = vaOptions[val];
              if(data.indexOf('$D$') > -1){
                if(obj[item.fieldCdNm] == null || obj[item.fieldCdNm] == ''){
                  obj[item.fieldCdNm] = data.replace('$D$','')
                }
              }
              const option = {
                text: data.replace('$D$',''),
                value: data.replace('$D$',''),
              }
              result.push(option)
            }
            return result
          }
        }
      }
      return result
    },
    setRowspan(item) {
      const fieldType = item.fieldType;

      // 필드 유형 : 리스트
      if(fieldType == 'CMS003.l') {
        const fieldDatas = item.fieldData.split(',');
        
        // 테이블 열 병합
        if(fieldDatas.length > 0) {
          return fieldDatas.length;
        }
      }
    },
    doIpinWindow() {
      this.$tran('/site/external/ipin/reqIpinCert', {}).then(response => {
        const ipinWindow = window.open('', 'ipinWindow', 'width=430, height=560, resizable=1, scrollbars=no, status=0, titlebar=0, toolbar=0, left=300, top=200' );
        this.reqNum = response.data.reqNum
        if(ipinWindow == null){ 
          this.$swal.fire(this.$t('error'), this.$t('IpinNotice'), 'error');
          return false;
        }

        let ipinForm = document.getElementById('reqPCCForm')
        if(ipinForm == null){
          ipinForm = document.createElement('form')
          ipinForm.setAttribute('id', "reqPCCForm")
          ipinForm.setAttribute('name', "reqPCCForm")
          ipinForm.setAttribute('method', "post") 
          ipinForm.setAttribute('action', "https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp") 
          ipinForm.setAttribute('target', "ipinWindow") 
        }

        let reqInfo = document.getElementById('reqPCCForm_reqInfo');
        if(reqInfo == null){
          reqInfo = document.createElement("input");
          reqInfo.setAttribute('type', 'hidden')
          reqInfo.setAttribute('name', 'reqInfo')
          reqInfo.setAttribute('id', "reqPCCForm_reqInfo")
          
          ipinForm.appendChild(reqInfo);
        }
        reqInfo.setAttribute('value', `${response.data.reqInfo}`)

        let retUrl = document.getElementById('reqPCCForm_retUrl');
        if(retUrl == null){
          retUrl = document.createElement("input");
          retUrl.setAttribute('type', 'hidden')
          retUrl.setAttribute('name', 'retUrl')
          retUrl.setAttribute('id', "reqPCCForm_retUrl")

          ipinForm.appendChild(retUrl);
        }
        retUrl.setAttribute('value', `32${location.protocol}//${location.host}/site/external/ipin/sciRetSjna?prepage=${response.data.prePage}`)

        let verSion = document.getElementById('reqPCCForm_verSion');
        if(verSion == null){
          verSion = document.createElement("input");
          verSion.setAttribute('type', 'hidden')
          verSion.setAttribute('name', 'verSion')
          verSion.setAttribute('id', "reqPCCForm_verSion")
          ipinForm.appendChild(verSion);
          verSion.setAttribute('value', '2')
        }

        document.body.appendChild(ipinForm);
        ipinForm.submit();
      })
    },
    doResult(param){
      param.reqNum = this.reqNum
      this.$tran('/site/external/ipin/resIpinCert', param).then(response => {
        if(!this.isModify) {
          this.detailMain.id = response.data.id
          this.detailMain.field02 = response.data.nm
        }else{
          if(this.board.pwYn != 'Y') {
            this.isPwChk = 'N'
          }
          this.certId=response.data.id; 
          this.doDetail();
        }

        this.$store.dispatch('setCertData', '');
      })
    },
    doCashDeletePopup() {
      const win = window.open('', 'CashDeletePopup', 'width=460px,height=475px,top=0,left=0, scrollbars=no');
      
      const htmlTag = `
        <style>
        .cash_delete_wrap{display:inline-block;}
        .cash_delete_wrap .tab_wrap{position:fixed;top:0;z-index:9;display:inline-block;width:410px;background:#5e637c;}
        .cash_delete_wrap .tab_wrap li{float:left;width:25%;text-align:center;border-left:1px solid #b0b0b2;box-sizing:border-box;}
        .cash_delete_wrap .tab_wrap li:first-child{border-left:0;}
        .cash_delete_wrap .tab_wrap li a{display:block;height:40px;line-height:40px;font-size:14px;font-family:'NotoKrM', sans-serif;color:#fff;letter-spacing:-0.05em;}
        .cash_delete_wrap .box{margin-top:20px;}
        .cash_delete_wrap .box img{width:100%;min-width:320px;margin-top:20px;}
        </style>
        <div class="cash_delete_wrap">
          <ul class="tab_wrap">
            <li><a href="#android">${this.$t('android')}</a></li>
            <li><a href="#iphone">${this.$t('iphone')}</a></li>
            <li><a href="#naverapp">${this.$t('naverApp')}</a></li>
            <li><a href="javascript:;" onclick="javascript:self.close();" class="btn">${this.$t('close')}</a></li>
          </ul>
          <div class="box">
            <div id="android">
              <img src="/nanum/site/builder/skin/common/img/cash_android.jpg"/>
            </div>
            <div id="iphone">
              <img src="/nanum/site/builder/skin/common/img/cash_iphone.jpg"/>
            </div>
            <div id="naverapp">
              <img src="/nanum/site/builder/skin/common/img/cash_naver.jpg"/>
            </div>
          </div>
        </div>
        `;
      win.document.write(htmlTag);
    },
    doKeydownIpinWindow(event) {
      if(event.keyCode == 13) {
      this.doIpinWindow();
      }
    },
    doKeydownSave(event) {
      if(event.keyCode == 13) {
        this.doSave();
      }
    },
    doKeydownList(event) {
      if(event.keyCode == 13) {
        this.doList();
      }
    },
    doKeydownBack(event) {
      if(event.keyCode == 13) {
        this.goBack();
      }
    },
  },
}
</script>