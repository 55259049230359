import Vue from 'vue'
import axios from 'axios';


const transaction = axios.create({
  timeout: 60000, // 요청시간 60초
  withCredentials: true
});

// 요청 인터셉터 추가
transaction.interceptors.request.use(
  (config) => {
    config.headers['Access-Control-Allow-Origin'] = window.origin
    config.headers['Access-Control-Allow-Headers'] = '*'
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
transaction.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default transaction
