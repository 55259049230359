<template>
  
</template>

<script>
export default {
  mounted() {
    opener.document.querySelector('.app').__vue__.$store.dispatch('setCertData', JSON.stringify(this.$route.query))
    self.close()
  },
}
</script>

<style>

</style>