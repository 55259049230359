import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layouts/LayoutMain.vue'
import ipinRet from '../views/ipin/ipinRet.vue'
import { sendPost } from '@/libs/comm/transection'


Vue.use(VueRouter)

const routes = [
  {
    path: '/ipinRet',
    name: 'ipinRet',
    component: ipinRet,
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/intro',
    children: [
      {
        path: '/intro',
        component: () => import('@/views/Main.vue'),
        name: 'intro'
      },
      {
        path: '/contents/content',
        component: () => import('@/views/contents/mainContent.vue'),
        name: 'content',
        props: true,
      },
      {
        path: '/contents/dynamicDetail',
        component: () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicDetail',
        props: true
      },
      {
        path: '/contents/dynamicModify',
        component : () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicModify',
        props: true
      },
      {
        path: '/contents/dynamicDetailExhbit',
        component : () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicDetailExhbit',
        props: true
      },
      {
        path: '/contents/dynamicDetailListVrt',
        component : () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicDetailListVrt',
        props: true
      },
      {
        path: '/contents/map',
        component: () => import('@/views/site/siteMap.vue'),
        name: 'siteMap',
        props: true
      },
      {
        path: '/weather/detail',
        component: () => import('@/views/weather/detail.vue'),
        name: 'weather',
      },
      {
        path: '/contents/dept',
        component: () => import('@/views/dept/dept.vue'),
        name: 'dept',
        props: true,
      },
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/Error404.vue'),
    name: '404',
    props: true
  },
  {
    path: '/directBoard',
    component: () => import('@/views/contents/content/directBoardContent.vue'),
    name: 'directBoard',
    props: true,
  },
  {
    path: '/directDetail',
    component: () => import('@/views/board/dynamicDetail.vue'),
    name: 'directDetail',
    props: true,
  },
  {
    path: '*',
    component: () => import ('@/views/Error404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if(to.hash == null || to.hash == ''){
      const voFrom = {...from.query}
      const voTo = {...to.query}
      if(to.path.indexOf('dynamicDetail') < 0){
        delete voFrom.postNo
        delete voTo.postNo
      }
      
      if(JSON.stringify(voFrom) != JSON.stringify(voTo)){
        return { x: 0, y: 0 }
      }
    }
  },
  base: process.env.BASE_URL,
  routes
})

//메뉴 클릭시 화면이 열리기 전에 제어
router.beforeEach((to, from, next) => {  
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  sendPost('/site/log/check', {...from});
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
