<template>
  <div>
    <input type="button" :value="$t('findZipNo')" class="ct_bt01" @click="showApi" style="padding: 5px 7px 3px 5px; margin-right:5px; width:110px" :tabindex="tabindex">
		<input type="text" size="5" :title="$t('zipNo5')" id="b_zip1" name="b_zip1" class="input_box board_input only_number" v-model="listData[0]" maxlength="5" :placeholder="$t('zipNo')" readonly="readonly" style="width: calc(100% - 124px)">	
		<div class="pt3">
			<input type="text" size="40" :title="$t('addr')" id="b_addr1" name="b_addr1" class="input_box board_input" v-model="listData[1]" maxlength="50" :placeholder="$t('addr')" readonly="readonly" style="width: calc(100% - 10px)"> 
			<input type="text" size="40" :title="$t('addrDtl')" id="b_addr2" name="b_addr2" class="input_box board_input" v-model="listData[2]" maxlength="50" :placeholder="$t('addrDtl')" style="width: calc(100% - 10px)" :tabindex="tabindex">
		</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    fieldData: {
      type: Object,
      default: () => {}
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    listData: {
      handler(newVal) {
        this.value[this.fieldData.fieldCdNm] = newVal
      }
    },
    value: {
      handler(newVal) {
        const data = newVal[this.fieldData.fieldCdNm];
        if(data !=null && data != ''){ 
          this.listData = data
        }
      }
    }
  },
  data(){
    return {
      listData:[]
    }
  },
  methods: {
    showApi() {
      const _pThis = this;
      new window.daum.Postcode({
        oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
            // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
            let extraRoadAddr = ''; // 도로명 조합형 주소 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }
            // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
            if(fullRoadAddr !== ''){
                fullRoadAddr += extraRoadAddr;
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            _pThis.$set(_pThis.listData, 0, data.zonecode); //5자리 새우편번호 사용
            _pThis.$set(_pThis.listData, 1, fullRoadAddr);
        }
      }).open()
    }
  }
}
</script>