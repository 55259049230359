<template>
  <div>
    <select :value="doReserve(value[fieldData.fieldCdNm], 'state')" @input="setFieldSelect(fieldData, 'state', $event)" style="width: 180px; padding-top: 3px; padding-bottom: 3px;" :tabindex="tabindex">
      <option value=""> {{$t('select')}}</option>
      <option v-for="(statItem, statIdx) in options" :value="statItem.value" :key="`select_${statIdx}`">{{statItem.text}}</option>
    </select>
    <input type="text" size="120" maxlength="1000" class="board_input ml10 postInput" :value="doReserve(value[fieldData.fieldCdNm], 'url')"  :readonly="doListLinkDisable(value[fieldData.fieldCdNm])" @change="setFieldValue(value[fieldData.fieldCdNm], 'url', $event)" :class="doDisabled()" :tabindex="tabindex"> 
  </div>
</template>

<script>
import {isJson} from "@/libs/comm/util";

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    fieldData: {
      type: Object,
      default: () => {}
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed: {
    options() {
      const fieldData = this.fieldData.fieldData;
      if(fieldData != null){
        if(isJson(fieldData)){
          const result = JSON.parse(fieldData)

          result.forEach((e)=>{
            if(!e.hasOwnProperty('value')){
              e.value = e.text
            }
          })
          
          return result
        }else{
          const result =[]; 
          fieldData.split(',').forEach((e) => {
            result.push({"text":e,"value":e}); 
            return result;
          })
          return result
        }
      }
      
      return []
    }
  },
  methods: {
    doReserve(e, column) {
      if(e != null && e != '') {
        const result = e.find((ev)=>{return ev.selected == 'Y'});
        if(result != null){
          return result[column]
        }
      }
      return ''
    },
    setFieldSelect(objData, column, e){
      const dataList = this.options;
      const result = [];
      const originData = this.value[objData.fieldCdNm]
      
      if(originData != null && originData.length > 0){
        originData.forEach (element => {
          if(element[column] == e.target.value){ 
            element.selected = 'Y'
          }else{
            element.selected = 'N'
          }
        })
      }else{
        dataList.forEach(element => {
          const data = {};

          if(element.hasOwnProperty('text')){
            data.state = element.text;
          }else{
            data.state = element;
          }

          let valueData = element
          
          if(element.hasOwnProperty('value')){
            valueData = element.value
          }else{
            if(element.hasOwnProperty('text')){
              valueData = element.text
            }
          }
          
          if(valueData == e.target.value){
            data.selected = 'Y'
          }else{
            data.selected = 'N'
          }
          
          data.url='';

          result.push(data);
        });
        this.$set(this.value, `${objData.fieldCdNm}`, result);
      }

      this.doDisabled();
    },
    setFieldValue(objData, column, e){
      const inputData = e.target.value;
      const info = objData.find((ev)=>{return ev.selected == 'Y'});

      if(info != null){
        info[column] = inputData;
      }
    },
    doListLinkDisable(objData) {
      if(objData != null && objData.length > 0){
        const selected = objData.findIndex((ev)=>{return ev.selected == 'Y'});
        
        if(selected > -1 && selected != 0){
          return false
        }
      }
      return true
    },
    getMaxLength(fieldNo){
      if(fieldNo == 'field03') {
        return 200
      }
      return 1000;
    },
    doDisabled() {
      const isDisabled = this.doListLinkDisable(this.value[this.fieldData.fieldCdNm]);
      
      if(isDisabled) {
        return 'inputDisabled';
      }

      return
    }
  }
}
</script>