import transaction from '@/libs/axios';
import { getLang, setNullData } from './util';


/**
* 공통코드조회
* data : json - 전송파라미터
*/
export async function selectComCdLists(_pThis, data, langYn=true) {
  const url = '/sys/code/selectComCdLists';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await transaction.post(url, { 'condCode': data }, config);
    const codeDatas = response.data;
    for (const key in codeDatas) {
      const codeList = codeDatas[key];
      if (langYn) {
        getLang(codeList.codeList);
      }
      if (codeList.nullData != null) {
        setNullData(codeList.codeList, codeList.nullData);
      }

      if (codeList.gridData != null) {
        const gridNms= codeList.gridData.gridNm;
        const colNm = codeList.gridData.colNm;
        if(gridNms instanceof Array && gridNms.length > 1){
          for(let i=0; i < gridNms.length; i++){
            const gridNm = gridNms[i];
            const def = _pThis[gridNm].gridApi.getColumnDef(colNm);  
            def.options = codeList.codeList;
          }
        }else{
          const def = _pThis[gridNms].gridApi.getColumnDef(colNm);
          def.options = codeList.codeList;
        }
        //_pThis[gridNm].gridApi.refreshCells({columns:[colNm]});
      }

      _pThis[key] = codeList.codeList;
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
* 범위코드조회
* data : json - 전송파라미터
*/
export async function selectAccCdList(data, langYn=true) {
  const url = '/sys/code/selectAccCdList';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await transaction.post(url, data, config);
    let codeList = response.data.codeList;
    if (langYn) {
      getLang(codeList);
    }
    if (data.nullData != null) {
      setNullData(codeList, data.nullData);
    }

    if (data.gridData != null) {
      const gridNm = data.gridData.gridNm;
      const colNm = data.gridData.colNm;

      const def = this[gridNm].gridApi.getColumnDef(colNm);
      def.options = codeList;

      //this[gridNm].gridApi.refreshCells({columns:[colNm]});
    }
    this[data.listNm] = codeList;
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
* 공통코드조회
* data : json - 전송파라미터
*/
export async function selectCommCodeList(data, langYn=true) {
  const url = '/sys/code/selectComCdList';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await transaction.post(url, data, config);
    let codeList = response.data.codeList;
    if (langYn) {
      getLang(codeList);
    }
    if (data.nullData != null) {
      setNullData(codeList, data.nullData);
    }

    if (data.gridData != null) {
      const gridNm = data.gridData.gridNm;
      const colNm = data.gridData.colNm;

      const def = this[gridNm].gridApi.getColumnDef(colNm);
      def.options = codeList;

      //this[gridNm].gridApi.refreshCells({columns:[colNm]});
    }
    this[data.listNm] = codeList;
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
* 공통코드조회
* data : json - 전송파라미터
*/
export async function getUserRole(menuPgId) {
  const url = '/sys/userRole';
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return await transaction.post(url, "menuPgId="+menuPgId, config)
}

/**
* 컨트롤러 호출
* data : json - 전송파라미터
*/
export function sendPost(url, data, isLodding='') {
  if(data == null || data == ''){
    data = {};
  }
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if(this != null && this.hasOwnProperty('lodding') && isLodding != null && isLodding != ''){
    this.lodding[isLodding] = true;

    return transaction.post(url, data, config).then(res=> {
      this.lodding[isLodding] = false;
      return res;
    }).catch(e=> {
      this.lodding[isLodding] = false;
      return Promise.reject(e);
    });
  }else{
    return transaction.post(url, data, config).then(res=> {
      return res;
    }).catch(e=> {
      return Promise.reject(e);
    });
  }
  
  
}

/**
* 컨트롤러 호출
* data : json - 전송파라미터
*/
export function sendPostFile(url, paramData={} ,fileList=new FormData) {
 
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  fileList.append('jsonData', JSON.stringify(paramData));

  return transaction.post(url, fileList, config).then(res=> {
    return res;
  }).catch(e=> {
    return Promise.reject(e);
  });
}

/**
* 컨트롤러 호출
* data : json - 전송파라미터
*/
export function sendPostImg(url, paramData={} ,fileList=new FormData()) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  fileList.append('jsonData', JSON.stringify(paramData));

  return transaction.post(url, fileList, config).then(res=> {
    return res;
  }).catch(e=> {
    return Promise.reject(e);
  });
}

/**
* 컨트롤러 호출
* data : json - 전송파라미터
*/
export function sendPostFileDown(url, data) {
  if(data == null || data == ''){
    data = {};
  }
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob'
  };

  return transaction.post(url, data, config).then(res=> {
    return res;
  }).catch(e=> {
    return Promise.reject(e);
  });
 
}