<template>
  <div class="kogl_box">
    <a :href="koglData.connCd1" class="img_area" target="_blank">
      <img :alt="koglData.connCd2" :src="koglData.connCd3" style="display:inline;">
    </a>
    <p class="txt_area">{{$t('acSjWork')}} 
      <a :href="koglData.connCd1" target="_blank">{{koglData.text}}</a> {{$t('useSj')}}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    koglCd: {
      type: String
    },
  },
  watch: {
    koglCd(newVal) {
      this.koglData = this.getCodeData(newVal)
    }
  },
  data() {
    return {
      condCode: [
        {cd: 'CMS015', useYn: 'Y', listNm: 'koglCdList'}
      ],
      koglCdList: [],
      koglData: {},
    }
  },
  mounted() {
    this.$codeLists(this, this.condCode).then(() => {
      this.koglData = this.getCodeData(this.koglCd)
    });
  },
  methods: {
    getCodeData(data) {
      return this.koglCdList.find((e) => {
        return e.cmnCd == data
      })
    }
  }
}
</script>