import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from "vue-cookies";
import fastAxios from '@/libs/fastAxios';

VueCookies.set("LOCALE", defaultLocale());

function defaultLocale() {
  return VueCookies.get("LOCALE") ?? 'ko';
}

export async function setI18n() {
  Vue.use(VueI18n)

  const langVersion = localStorage.getItem('LANG_VERSION');
  const langMessage = localStorage.getItem('LANG_MESSAGE');
  let message = {};
  let version = '';

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const resVer = await fastAxios.post('/sys/lang/selectPublicLangVer', {}, config);
  version = resVer.data;
  localStorage.setItem('LANG_VERSION', version);

  if(langVersion == null || langVersion == '' || langVersion < version || langVersion == '0'){
    const res = await fastAxios.post('/sys/lang/selectPublicLangList', {}, config);
    message = res.data;
    localStorage.setItem('LANG_MESSAGE', JSON.stringify(message));
  }

  if(Object.keys(message).length == 0){
    message = JSON.parse(langMessage);
  }

  VueCookies.set("LOCALE", defaultLocale());

  return new VueI18n({
    locale: VueCookies.get("LOCALE"),
    fallbackLocale: 'ko',
    messages: message,
    version: version
  })
}

export default setI18n
