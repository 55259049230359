import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    depth1All: [],  // 1뎁스 메뉴(전체)
    depth2All: [],  // 2뎁스 메뉴(전체)
    depth3All: [],  // 3뎁스 메뉴(전체)
    curMenuNm: '',  //  컨텐츠 상단(cont_head)에 표시될 현재 메뉴이름(mulLangCd)
    certData: '', // 휴대폰 인증정보
    metaInfo: {}, // 메타태그정보
    isNoPage: false,
    siteContext: ''
  },
  getters: {
    depth1All(state) {
      return state.depth1All
    },
    depth2All(state) {
      return state.depth2All
    },
    depth3All(state) {
      return state.depth3All
    },
    curMenuNm(state) {
      return state.curMenuNm
    },
    certData(state) {
      return state.certData
    },
    metaInfo(state) {
      return state.metaInfo
    },
    isNoPage(state) {
      return state.isNoPage
    },
    siteContext(state) {
      return state.siteContext
    },
  },
  mutations: {
    setDepth1All(state, __depth1All){
      state.depth1All = __depth1All
    },
    setDepth2All(state, __depth2All){
      state.depth2All = __depth2All
    },
    setDepth3All(state, __depth3All){
      state.depth3All = __depth3All
    },
    setCurMenuNm(state, __curMenuNm){
      state.curMenuNm = __curMenuNm
    },
    setCertData(state, __certData) {
      state.certData = __certData
    },
    setMetaInfo(state, __metaInfo) {
      state.metaInfo = __metaInfo
    },
    setIsNoPage(state, __isNoPage) {
      state.isNoPage = __isNoPage
    },
    setSiteContext(state, __siteContext) {
      state.siteContext = __siteContext
    },
  },
  actions: {
    setDepth1All({commit}, __depth1All){
      return new Promise((resolve, reject) => {
        commit('setDepth1All' , __depth1All);
        resolve(__depth1All);
      });
    },
    setDepth2All({commit}, __depth2All){
      return new Promise((resolve, reject) => {
        commit('setDepth2All' , __depth2All);
        resolve(__depth2All);
      });
    },
    setDepth3All({commit}, __depth3All){
      return new Promise((resolve, reject) => {
        commit('setDepth3All' , __depth3All);
        resolve(__depth3All);
      });
    },
    setCurMenuNm({commit}, __curMenuNm) {
      return new Promise((resolve, reject) => {
        commit('setCurMenuNm' , __curMenuNm);
        resolve(__curMenuNm);
      });
    },
    setCertData({commit}, __certData) {
      return new Promise((resolve, reject) => {
        commit('setCertData', __certData);
        resolve(__certData)
      })
    },
    setMetaInfo({commit}, __metaInfo) {
      return new Promise((resolve, reject)=> {
        commit('setMetaInfo', __metaInfo);
        resolve(__metaInfo)
      })
    },
    setIsNoPage({commit}, __isNoPage) {
      return new Promise((resolve, reject)=> {
        commit('setIsNoPage', __isNoPage);
        resolve(__isNoPage)
      })
    },
    setSiteContext({commit}, __siteContext) {
      return new Promise((resolve, reject)=> {
        commit('setSiteContext', __siteContext);
        resolve(__siteContext)
      })
    },
  },
  modules: {
  }
})
