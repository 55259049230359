<template>
  <div id="board" style="width:100%;">
    <!-- 그룹 탭 -->
    <childTab v-if="isTab()" :grpList="grpList" v-model="currentTab" @changeTab="changeTab" :depth="depth" :tabindex="tabindex+1"/>

    <div class="board_total_left"></div>
    <!-- 상단 -->
    <div class="top">
      <div class="cal_top">
        <a class="t_arrow y_pre" @click="doChangeDate(prevYear-1, intThisMonth)" @keydown="doKeydownChangeDate($event, prevYear-1, intThisMonth)" :tabindex="tabindex+2">
          <span class="blind">{{$t('prevYear')}}</span>
        </a>
        <a class="t_arrow m_pre" @click="doChangeDate(prevYear, prevMonth)" @keydown="doKeydownChangeDate($event, prevYear, prevMonth)" :tabindex="tabindex+3">
          <span class="blind">{{$t('prevMonth')}}</span>
        </a> 
        
        <!-- 선택된 년월 -->
        <span class="t_date">{{ intThisYear }}. {{ intThisMonth2 }}</span>
        <a class="t_arrow m_next" @click="doChangeDate(nextYear, nextMonth)" @keydown="doKeydownChangeDate($event, nextYear, nextMonth)" :tabindex="tabindex+4">
          <span class="blind">{{$t('nextMonth')}}</span>
        </a>

        <a class="t_arrow y_next" @click="doChangeDate(nextYear+1, intThisMonth)" @keydown="doKeydownChangeDate($event, nextYear+1, intThisMonth)" :tabindex="tabindex+5">
          <span class="blind">{{$t('nextYear')}}</span>
        </a>
        <a class="t_today" @click="doChangeDate()" @keydown="doKeydownChangeDate($event)" :tabindex="tabindex+6">{{$t('today')}}</a>
      </div>
      <div class="cal_right"></div>
    </div>

    <!-- 리스트 테이블 -->
    <div class="cal_blist"> 
      <table cellspacing="0" :summary="`${title}` +`${$t('detailConfirm')}`">
        <caption>{{ title }}</caption>
        <colgroup>
          <col width="14%" />
          <col width="14%" />
          <col width="14%" />
          <col width="14%" />
          <col width="14%" />
          <col width="14%" />
          <col width="14%" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col" class="sun">{{$t('sunday')}}</th>
            <th scope="col">{{$t('monday')}}</th>
            <th scope="col">{{$t('tueday')}}</th>
            <th scope="col">{{$t('wedday')}}</th>
            <th scope="col">{{$t('thuday')}}</th>
            <th scope="col">{{$t('friday')}}</th>
            <th scope="col" class="sat">{{$t('satday')}}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in jucnt">
            <!-- 날짜 -->
            <tr :key="`date_${index}`">
              <th v-for="index2 in 7" :key="index2" :class="setThClass(index2)"> {{ doDate(index, index2) }}</th> 
            </tr>
            <!-- 내용 -->
            <tr :key="`content_${index}`">
              <td v-for="index2 in 7" :key="index2" :headers="'schedule date'+doDate(index, index2)">
                <ul v-for="(subItem, subIdex) in doPost(doDate(index, index2))" :key="subIdex">
                  <a @click="doLink(subItem)" @keydown="doKeydownLink($event, subItem)" :tabindex="tabindex+subIdex+7"> 
                    {{ board.ndisYn == 'Y' && (board.ndisRequYn == 'Y' || subItem.ndisYn == 'Y') ?`${$t('ndisYn')}` : subItem.field03}}
                    <!-- 댓글 수 -->
                    <span v-if="subItem.commCnt > 0" class="reply_count">({{item.commCnt}})</span>
                    <!-- 비밀번호 -->
                    <img v-if="board.writeYn == 'Y' && subItem.pwYn == 'Y'" class="pl3" src="@/assets/img/board/nninc_simple/img/icon_key.gif" :alt="$t('scrPost')">
                    <!-- 첨부파일 -->
                    <img v-if="subItem.attcCnt > 0" class="pl5" src="@/assets/img/board/nninc_simple/img/icon_file.gif" :alt="$t('fileAttach')">
                  </a>
                </ul>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!-- 버튼 -->
    <div class="board_button">
      <div class="fr">
        <span class="bt" v-if="board.writeYn == 'Y'">
          <router-link :to="doWrite()" class="cbtn cbtn_point" :tabindex="tabindex+dataItemList.length+8">{{$t('write')}}</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import childTab from '@/views/component/ChildTab'
import { boardDefaults } from '@/libs/sanitize'

export default {
  components: {
    childTab,
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    grpList: {
      type: Array,
      default: () => []
    },
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  computed:{
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    }
  },
  watch: {
    'board.boardNo'(newVal){
      if(this.searchParam.contentNo != newVal) {
        this.setSearchParam();
        this.subContentNo = null;

        if(this.board.grpDispCd == 'CMS009.select' && this.grpList != null && this.grpSize > 0) {
          this.isTabList = true;
        }

        if(this.grpList != null && this.grpSize > 0){
          if(this.isTabList || this.board.grpYn != 'Y' || this.board.ndisYn == 'Y' ) {
            const query = {...this.$route.query};

            this.searchParam.grpCd = query[`s${this.depth}`];
            this.doList();
          }else{
            this.setCurrentTab();
          }
        } else {
          this.doList();
        }
      }
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.setSearchParam();
          this.currentPageIdx = newVal.page;
          
          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab();
          }

          // 컨텐츠유형 : 게시판 
          if(this.board.grpYn == 'N' && this.searchParam.grpCd == null || this.searchParam.grpCd == '') {
            if(newVal[`s${this.depth-1}`] == oldVal[`s${this.depth-1}`]) {
              // 탭 변경 이외 조회가능하게 
              this.doList()
            } 
          }else{
            // 컨텐츠유형 : 분류
            this.doList();
          }
        }
      }
    }
  },
  //뒤로 가기나 메뉴 이동을 통한 조회를 하기 위해 존재함
  created() {
    const grpYn = this.board?.grpYn;
    const grpDispCd = this.board?.grpDispCd;
    const ndisYn = this.board.ndisYn;

    this.setSearchParam();

    if(this.$route.name.indexOf('direct') == 0) {
      this.routeDiv = 'direct';
    }

    //분류가 존재하는경우 분류를 통한 조회
    if(this.grpList != null && this.grpSize > 0 && grpYn == 'Y') {
      if(ndisYn == 'Y' || grpDispCd == 'CMS009.select'){
        // 게시물 상세에서 뒤로가기 진행시 게시판 설정이 비공개이거나 선택상자 사용시 탭 세팅 안하고 바로 조회
        this.doList();
      }else{
        this.setCurrentTab()
      }
    }else{
      const boardNo = this.board?.boardNo;

      if(boardNo != null && boardNo != '') {
        // 글쓰기 및  상세 & 뒤로가기 할 때 조회 되게 
        this.doList();
      }
    }
  },
  data() {
    return {
      searchParam: {
        contentNo: '',
        grpCd: null, 
        year: '',
        month: '',
      },
      title: '',
      dataItemList: [],
      datFirstDay: null,
      intLastDay: null,
      jucnt: null,
      prevYear: null,
      prevMonth: null,
      nextYear: null,
      nextMonth: null,
      intThisYear: null,
      intThisMonth: null,
      intThisMonth2: null,
      currentTab: 'none',
      fileInfo: {
        fileComplet: null,
        fileSize: '5242880', 
        fileExt: ['hwp', 'doc', 'ppt', 'xls', 'txt', 'gif', 'jpg', 'docx', 'pptx', 'xlsx', 'zip', 'alz', 'rar', 'pdf'],
        fileCnt: 2,
        multiple: false,
        fileDownloadNm: this.$t('attcFile'),
      },
      isTabList: false,
      isContent: false,
      subContentNo: '',
      subContentType: 'A',
      routeDiv: 'dynamic',
      boardDefaults
    }
  },
  methods: {
    //기본파라미터 세팅
    setSearchParam() {
      const query = this.$route.query;
      const grpYn = this.board?.grpYn;
      const grpCd = query[`s${this.depth}`];
      const year = query?.year;
      const month = query?.month;

      if(grpYn == 'Y') {
        this.searchParam.grpCd = grpCd;
      }

      this.searchParam.contentNo = this.board.boardNo;
      this.searchParam.year = year;
      this.searchParam.month  = month;

      if(this.board.attcYn == 'Y') {
        this.fileInfo = {
          fileComplet: null,
          fileSize: this.board.attcLimitSize * 1048576, 
          fileExt: this.board.attcAllowExts,
          fileCnt: this.board.attcLimitNo,
          multiple: true,
          fileDownloadNm: this.$t('attcFile'),
        }
      }   
    },
    // 메타태그
    setMetaInfo() {
      const query = this.$route.query;
      // 현재 뎁스의 그룹코드
      const grpCd = query[`s${this.depth}`];
      // 현재 URL
      const url = document.location.href;
      // 게시판 이름
      const boardNm = this.board.boardNm;
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 그룹리스트
      const grpList = this.grpList;

      let metaInfoData = {
        title: boardNm,
        ogTitle: boardNm,
        ogUrl: url,
        ogDescription: boardNm,
        ogImage: '/logo.png',
      };
      
      if(grpList != null && this.grpSize > 0 && grpYn == 'Y') {
        const curTabData = grpList.find(e => e.grpCd == grpCd);

        if(curTabData != null) {
          const grpNm = curTabData.grpNm;
          if(grpNm != null && grpNm != '') {
            metaInfoData.title = `${this.$t('nationalSj')}: ${boardNm} > ${grpNm}`;
          }
        }
      }else{
        metaInfoData.title = `${this.$t('nationalSj')}: ${boardNm}`;
      }
  
      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
    isTab(){
      // 그룹 탭 리스트 
      const grpList = this.grpList;
      // 그룹 사용 컴포넌트 코드(탭 / 선택상자)
      const grpDispCd = this.board.grpDispCd;
      // 그룹사용여부
      const grpYn = this.board.grpYn;

      if(grpList == null || this.grpSize < 1){
        return false;
      }

      if(grpYn != 'Y' || grpDispCd != 'CMS009.tab'){
        return false;
      }

      return true;
    },
    changeTab(data, contentType, key, isSameAllow=false) {
      const query = {...this.$route.query};
      query[`s${this.depth}`] = key;
      let isSearch = true; 

      //주소가 같이 않으면주소 변경
      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
        
        if(contentType != 'A') {
          this.searchParam.grpCd = data
        }else{
          this.searchParam.contentNo = data
        }

        isSearch = false;
        this.$router.push(param);
      }

      //컨텐츠가 분류가아니면 하위 컴포넌트 조회
      if(contentType != 'A'){
        //하위컨텐츠의 타입 전달
        this.subContentType = contentType
        this.isContent = true;
        this.subContentNo = data
      }else{
        this.isContent = false;
        this.subContentNo = '';

        //그룹코드를 통한 데이터 조회를 위한 설정
        if(this.searchParam.grpCd == null || this.searchParam.grpCd == '') {
          this.searchParam.grpCd = data
        }

        if(isSearch){
          this.doList()
        }
      }
    },
    setCurrentTab() {
      const isFirst = this.$route.params.isFirst
      
      if(isFirst != null&& isFirst != ''){
        const grpList = this.grpList;

        if(grpList != null && this.grpSize > 0){
          const firstGrpList = grpList[0];
          const grpCd = firstGrpList.grpCd;
          const contentType = firstGrpList.contentType;
          const linkUrl = firstGrpList.linkUrl;

          if(this.currentTab == null){
            this.changeTab(contentType!='A'?linkUrl:grpCd,contentType,grpCd);
          }else{
            this.currentTab = grpCd;
          }
        }
      }else{
        this.currentTab = this.$route.query[`s${this.depth}`]
      }
    },
    doList() {
      this.$tran('/site/external/contents/selectPostCalendarList', this.searchParam).then(response => {
        this.dataItemList = response.data.postList;
        this.datFirstDay = response.data.datFirstDay;
        this.intLastDay = response.data.intLastDay;
        this.jucnt = response.data.jucnt;
        this.prevYear = response.data.prevYear;
        this.prevMonth = response.data.prevMonth;
        this.nextYear = response.data.nextYear;
        this.nextMonth = response.data.nextMonth;
        this.intThisYear = response.data.intThisYear;
        this.intThisMonth = response.data.intThisMonth;
        this.intThisMonth2 = response.data.intThisMonth2;
        
        this.$nextTick(() => {
          this.title = this.board.boardNm;
          this.setMetaInfo();
        })
        
      })
    },
    doChangeDate(year, month) {
      const query = {...this.$route.query};
      query.year = year;
      query.month = month;

      //주소가 같이 않으면주소 변경
      if(JSON.stringify(this.$route.query) != JSON.stringify(query)) {
        const param = {query};

        this.$router.push(param);
      }
    },
    setThClass(index) {
      if(index == 1) {
        return 'day sun';
      }else if(index == 7) {
        return 'day sat';
      }else{
        return 'day';
      }
    },
    doDate(index, index2) {
      const startDay = -(this.datFirstDay+1) + 1; 
      const addDate = (7*index)+index2;
    
      const dateText = startDay+addDate
      
      if(dateText > 0 && dateText <= this.intLastDay) {
        return dateText;
      }

      return 
    },
    doLink(item) {
      const query = {...this.$route.query};

      query['postNo'] = item.postNo;

      if(this.board.grpYn == 'Y') {
        query[`s${this.depth}`] = item.grpCd;
      }
      
      this.$router.push({name: `${this.routeDiv}Detail`, query: query});
    },
    doPost(date) {
      if(10 > date) {
        date = `0${date}`
      }
  
      const data = this.dataItemList.filter((e)=> e.postSt.slice(-2) == date || e.postEn.slice(-2) == date);

      if(data != null && data.length > 0) {
        return data;
      }

      return  
    },
    doWrite() {
      const query = {...this.$route.query}

      return {name:`${this.routeDiv}Modify`, query: query, params: {depth: this.depth}}
    },
    doKeydownChangeDate(event, year, month) {
      if(event.keyCode == 13) {
        this.doChangeDate(year, month);
      }
    },
    doKeydownLink(event, item) {
      if(event.keyCode == 13) {
        this.doLink(item);
      }
    }
  }
}
</script>