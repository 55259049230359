<template>
  <!-- 최상단 -->
  <div class="tmenu">
    <div class="wsize">
      <ul class="sitelink">
        <li v-for="(item, index) in topLinkList" :key="index" v-html="$sanitize(item.content)" @click="openWindow(item.url)">
        </li>
      </ul>
      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    },
    topLinkList: {
      type: Array,
      default: ()=> []
    }
  },
  methods: {
    openWindow(url){
      if(url == null || url == ''){
        return 
      }
      if(url.indexOf(window.location.origin) == 0 || url.indexOf('/') == 0){
        window.location.href = url
      }else{
        window.open(url)
      }
    },
  }
}
</script>
