<template>
  <div class="board_paginate">
      <template v-if="pagingInfo.prevPage > 0">
        <a @click="doPage(1)" @keydown="doKeydownLink($event, 1)" class="pre" :tabindex="tabindex+1">{{$t('first')}}</a>
        <a @click="doPage(pagingInfo.prevPage)" @keydown="doKeydownLink($event, pagingInfo.prevPage)" class="pre" :tabindex="tabindex+2">{{$t('before')}}</a>
      </template>
      <template v-for="(index) in (pagingInfo.lastPage - pagingInfo.firstPage) + 1">
        <strong v-if="(index+pagingInfo.firstPage)-1 == currentPage" :key="`selected_${index}`" :tabindex="tabindex+3">{{(index+pagingInfo.firstPage)-1}}</strong>
        <a v-else @click="doPage((index+pagingInfo.firstPage)-1)" @keydown="doKeydownLink($event, (index+pagingInfo.firstPage)-1)" :key="`noselected_${index}`" :tabindex="tabindex+3">{{(index+pagingInfo.firstPage)-1}}</a>
      </template>
      <template v-if="pagingInfo.nextPage > 0">
        <a @click="doPage(pagingInfo.nextPage)" @keydown="doKeydownLink($event, pagingInfo.nextPage)" class="next" :tabindex="tabindex+4">{{$t('next')}}</a>
        <a @click="doPage(pagingInfo.totalPage)" @keydown="doKeydownLink($event, pagingInfo.totalPage)" class="next" :tabindex="tabindex+5">{{$t('end')}}</a>
      </template>
    </div>
</template>

<script>
export default {
  props: {
    pagingInfo: {
      type: Object
    },
    currentPage: {
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  methods: {
    doPage(index) {
      this.$emit("input", index)
    },
    doKeydownLink(event, index) {
      if(event.keyCode == 13) {
        this.doPage(index);
      }
    }
  }
}
</script>