import { render, staticRenderFns } from "./dynamicListVrt.vue?vue&type=template&id=43f1fcd8"
import script from "./dynamicListVrt.vue?vue&type=script&lang=js"
export * from "./dynamicListVrt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports