<template>
  <div>
    <div class="harea">	
      <h1 class="logo">
        <router-link to="/">
          <img src="@/assets/img/builder/logo_color.png" :alt="$t('nationalSj')" :tabindex="tabindex">
        </router-link>
      </h1>
      
      <!-- 메뉴영역 -->
      <div id="menu_navi" class="active">
        <ul>
          <li v-for="(item, index) in depth1List" :class="`menu${index} oneD_cont`" :key="index">
            <template v-if="item.hiddenYn != 'Y'">
              <a @click="depth1Link(item)" @mouseenter="doMouseEnter(item,index)" @focus="doTopLinkFocus(item,index)" @keydown="doKeydownDepth1Link($event, item)" :class="item.rinkClass!= null?item.rinkClass:['oneD']" :style="item.rinkStyle!=null?item.rinkStyle:{color: 'rgb(0, 0, 0)'}" :tabindex="tabindex+1">
                <span v-html="$sanitize($t(item.mulLangCd))"></span>
              </a>
              <transition name="slide" v-if="curDepth1Menu == item.menuId">
                <div class="twoD_cont" :style="doTowDStyle(item)" @mouseleave="doMouseLeave()" v-show="isShow">
                  <div class="gnb_bg" :style="gnbBgStyle">
                    <div class="wsize">
                      <div class="con_fl">
                        <p class="smtit">
                          <span v-html="$sanitize($t(item.mulLangCd))"></span>
                        </p>
                      </div>
                      <div class="menu_box">
                        <div class="area">
                          <template v-for="(subItem, subIndex) in depth2">
                            <div class="box" v-if="subItem.hiddenYn != 'Y'" :key="subIndex">
                              <ul>
                                <li>
                                  <a @click="depth2Link(subItem)" @keydown="doKeydownDepth2Link($event, subItem)" :tabindex="tabindex+1">
                                    <span v-html="$sanitize($t(subItem.mulLangCd))"></span>
                                    <img src="@/assets/img/skin01/img/common/new_window_icon.gif" v-if="subItem.menuTypeCd=='CMS007.link' && subItem.link.charAt(0) != '/'" :alt="$t('window')" class="ml10">
                                  </a>
                                  <ul>
                                    <li v-for="(su3Item, sub3Index) in depth3(subItem)" :key="sub3Index">
                                      <a v-if="su3Item.hiddenYn != 'Y'" @click="depth3Link(su3Item)" @keydown="doKeydownDepth3Link($event, su3Item)" :tabindex="tabindex+1">
                                        <span v-html="$sanitize($t(su3Item.mulLangCd))"></span>
                                        <img src="@/assets/img/skin01/img/common/new_window_icon.gif" v-if="su3Item.menuTypeCd=='CMS007.link' && su3Item.link.charAt(0) != '/'" :alt="$t('window')" class="ml10">
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </transition>
            </template>
          </li>
        </ul>
      </div>

      <!-- 언어 -->
      <div class="mnara">
        <p class="btn_nara">
          <a href="/" class="nara_ico ico01">
            <span>{{$t('selectLang')}}</span>
          </a>
        </p>
      </div>
    </div>
    <ul class="aside_menu">
      <li class="mobile_btn" id="totalmenu">
        <a @click="mobileMenuClick()">{{$t('mobileMenu')}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed: {
    upMenuId(){
      return this.$route.query?.upMenuId;
    },
    menuId(){
      return this.$route.query?.menuId;
    },
    depth2() {
      if(this.depth2List != null) {
        return this.depth2List.filter((e) => {
          return e.seqChar.indexOf(this.curDepth1Menu) > -1
        })
      }else {
        return []
      }
    },
    depth3() {
      return (depth2) => {
        if(this.depth3List != null) {
          return this.depth3List.filter((e) => {
            return e.seqChar.indexOf(depth2.menuId) > -1
          })
        }else{
          return []
        }
      }
    }
  },
  created() {
    this.doTopMenuList();
  },
  data() {
    return {
      depth1List: [],
      depth2List: [],
      depth3List: [],
      gnbBgStyle: {display: 'none'},
      isShow: false,
      curDepth1Menu: null,
    }
  },
  methods: {
    // 전체메뉴 조회
    doTopMenuList() {
      this.$tran('/site/external/main/selectTopMenuListSjna','').then(response => {
        this.depth1List = response.data.depth1List;
        this.depth2List = response.data.depth2List;
        this.depth3List = response.data.depth3List;

        this.$store.dispatch('setDepth1All', this.depth1List);
        this.$store.dispatch('setDepth2All', this.depth2List);
        this.$store.dispatch('setDepth3All', this.depth3List);
      })  
    },
    // 1뎁스 링크
    depth1Link(depth1) {
      let menuTypeCd = null;
      const param = {};
      const depth2MenuList = this.depth2;
      let item = {};

      if(depth2MenuList != null && depth2MenuList.length > 0) {
        const curDepth2 = depth2MenuList[0];
        item = depth2MenuList[0];
        let depth3MenuList = this.depth3(curDepth2);
        
        if(depth3MenuList != null && depth3MenuList.length > 0) {
          // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
          const curDepth3 = depth3MenuList[0];
          item = depth3MenuList[0];
          menuTypeCd = curDepth3.menuTypeCd;
          param.query = {menuId: curDepth3.menuId, upMenuId: curDepth3.upMenuId, s0:curDepth3.firstGrpCd};  
        }else{
          // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
          menuTypeCd = curDepth2.menuTypeCd;
          param.query = {menuId: curDepth2.menuId, upMenuId: curDepth2.upMenuId, s0:curDepth2.firstGrpCd}; 
        }
      }else{
        // 1뎁스(menuId: 1뎁스menuId/upmenuId: 1뎁스menuId)
        item = depth1;
        menuTypeCd = depth1.menuTypeCd;
        param.query = {menuId: depth1.menuId, upMenuId: depth1.menuId, s0:depth1.firstGrpCd}; 
      }
     
      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId} 

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenuNm', item.mulLangCd);
        this.$router.push(param)
      }

      this.doMouseLeave();
    },
    // 2뎁스 링크
    depth2Link(depth2) {
      let menuTypeCd = null;
      const param = {};
      let item = {};

      let depth3MenuList = this.depth3(depth2).filter(e=> e.hiddenYn == 'N');

      if(depth3MenuList != null && depth3MenuList.length > 0) {
        // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
        const curDepth3 = depth3MenuList[0];
        item = depth3MenuList[0];
        menuTypeCd = curDepth3.menuTypeCd;
        param.query = {menuId: curDepth3.menuId, upMenuId: curDepth3.upMenuId, s0:curDepth3.firstGrpCd};  
      }else{
         // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
        menuTypeCd = depth2.menuTypeCd;
        item = depth2;
        param.query = {menuId: depth2.menuId, upMenuId: depth2.upMenuId, s0:depth2.firstGrpCd}; 
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);
      param.params = {isFirst: item.menuId};

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenuNm', item.mulLangCd);
        this.$router.push(param)
      }

      this.doMouseLeave();
    },
    // 3뎁스 링크
    depth3Link(depth3) {
      const menuTypeCd = depth3.menuTypeCd;
      const param = {};
  
      // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
      param.query = {menuId: depth3.menuId, upMenuId: depth3.upMenuId, s0:depth3.firstGrpCd};  

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = depth3?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {
          const contentLink = depth3.link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부 링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }

      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:depth3.menuId};

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenuNm', depth3.mulLangCd);
        this.$router.push(param);
      }

      this.doMouseLeave();
    },
    doMouseEnter(item,index) {
      this.curDepth1Menu = item.menuId;

      for(let i = 0; i<this.depth1List.length; i++ ) {
        let data = this.depth1List[i];

        if(data.menuId == this.curDepth1Menu) {
          this.$set(item, 'rinkClass', ['oneD', 'over', 'active'])
          this.$set(this.depth1List[i], 'rinkStyle', {color: 'rgb(34, 34, 34)'});
        }else{
          this.$set(this.depth1List[i], 'rinkClass', ['oneD'])
          this.$set(this.depth1List[i], 'rinkStyle', {color: 'rgb(0, 0, 0)'});
        }
      }

      this.isShow=true;
      this.gnbBgStyle.display='block';
    },
    doTowDStyle(item) {
      const rinkClass = item.rinkClass;

      if(rinkClass != null && rinkClass.includes('active')) {
        return {display:'block', zIndex: '20'};
      }

      return {display:'none'};
    },
    doMouseLeave() {
      this.depth1List.forEach(e => {
        this.$set(e, 'rinkClass', ['oneD'])
      })

      this.isShow=false;
      this.gnbBgStyle.display='none';
    },
    mobileMenuClick(){
      this.$emit('onMobileMenu');
    },
    doTopLinkFocus(item, index) {
      this.doMouseEnter(item, index);
    },
    doKeydownDepth1Link(event, item) {
      if(event.keyCode == 13) {
        this.depth1Link(item);
      }
    },
    doKeydownDepth2Link(event, item) {
      if(event.keyCode == 13) {
        this.depth2Link(item);
      }
    },
    doKeydownDepth3Link(event, item) {
      if(event.keyCode == 13) {
        this.depth3Link(item);
      }
    }
  },
}
</script>

<style scoped>
.slide-enter-active { 
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
  max-height: 100vh;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
