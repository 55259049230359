<template>
  <span :class="classData">
    <template v-if="isJson(fieldData)">
      {{selectedData}}
    </template>
    <template v-else>
      {{value}}
    </template>
  </span>
</template>

<script>
import {isJson} from "@/libs/comm/util";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    fieldData: {
      type: String,
      default: '[]'
    }
  },
  computed:{
    selectedData(){
      let result = '';
      if(this.value != null && this.value != ''){
        const jsonData = this.fieldData
        if(jsonData != null && jsonData != ''){
          const originData = JSON.parse(jsonData.replaceAll("'",'"'));
          for(const i in originData){
            const data = originData[i];
            if(data.hasOwnProperty('value')){
              if(data.value == this.value){
                result = data.text
              }
            }else{
              if(data.text == this.text){
                result = data.text
              }
            }
          }
        }
      }
      return result
    },
    classData(){
      const fieldData = this.fieldData;
      if(fieldData != null){
        if(isJson(fieldData)){
          const originData = JSON.parse(this.fieldData.replaceAll("'",'"'));  
          for(const i in originData){
            const data = originData[i];
            if(data.hasOwnProperty('value') && data.value == this.value){
              return data.class
            }else if(data.hasOwnProperty('text') && data.text == this.value){
              return data.class
            }
          }
        }
      }
      
      return {}
    }
  },
  data() {
    return {
      date: null
    }
  },
  methods: {
    isJson(str) {
      return isJson(str)
    },
  },
}
</script>